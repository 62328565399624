import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box, Checkbox, FormControlLabel, TextField, Typography, Button,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function ExpirationCertificatesReportForm({ initialValues, onSubmit }) {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    reportCertificateExpiration: Yup.object({
      active: Yup.boolean(),
      emails: Yup.array()
        .of(Yup.string().email(t('invalid_email')).required(t('required')))
        .test('unique-emails', t('duplicate_emails'), (emails) => {
          if (!emails) return true;
          const uniqueEmails = new Set(emails);
          return uniqueEmails.size === emails.length;
        }),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleAddEmail = () => {
    const newEmails = [...formik.values.reportCertificateExpiration.emails, ''];
    formik.setFieldValue('reportCertificateExpiration.emails', newEmails);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = formik.values.reportCertificateExpiration.emails.filter((_, i) => i !== index);
    formik.setFieldValue('reportCertificateExpiration.emails', newEmails);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1,
        mt: 2,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Typography component="span" variant="h6" sx={{ alignSelf: 'center' }}>
        {t('admin.report_certif_title')}
      </Typography>
      <Typography
        component="span"
        variant="caption"
      >
        {t('admin.report_certif_info')}
      </Typography>

      <FormControlLabel
        control={(
          <Checkbox
            name="reportCertificateExpiration.active"
            checked={formik.values.reportCertificateExpiration.active}
            onChange={formik.handleChange}
          />
          )}
        label="Active"
      />

      {formik.values.reportCertificateExpiration.emails.map((email, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`email-field-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TextField
            size="small"
            id={`reportCertificateExpiration.emails.[${index}]`}
            name={`reportCertificateExpiration.emails.[${index}]`}
            value={email}
            label={`${t('email')} ${index + 1}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.reportCertificateExpiration?.emails?.[index]
              && Boolean(formik.errors.reportCertificateExpiration?.emails?.[index])}
            helperText={formik.touched.reportCertificateExpiration?.emails?.[index]
              && formik.errors.reportCertificateExpiration?.emails?.[index]}
          />
          <IconButton onClick={() => handleRemoveEmail(index)} size="small">
            <RemoveIcon />
          </IconButton>
        </Box>
      ))}

      <Button onClick={handleAddEmail} size="medium" variant="outlined" endIcon={<AddIcon />}>
        {t('admin.add_email')}
      </Button>

      <Button variant="contained" color="primary" type="submit" size="small">
        Save
      </Button>

    </Box>
  );
}

export default ExpirationCertificatesReportForm;
