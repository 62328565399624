import { LoadingButton } from '@mui/lab';
import {
  Box, Button, CircularProgress, Divider, List, Typography,
} from '@mui/material';
import useGlobal from 'global-state/store';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  doc, deleteDoc, query, collection, getDocs, writeBatch,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import NewCertificateItem from './NewCertificateItem';
import RejectedCertificates from './RejectedCertificates';

export default function NewCertificatesAvailableDetail({ notification: notificationParam, handleCloseDetail }) {
  const firestore = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const acceptNewCertificatesCall = httpsCallable(functions, 'acceptNewCertificatesCall');
  const notification = { ...notificationParam };
  const [pageStatus, setPageStatus] = useState('initial');
  const [cellars, setCellars] = useState([]);
  const [newCellars, setNewCellars] = useState([]);
  const [rejectedCerts, setRejectedCerts] = useState([]);
  const [rejectMessage, setRejectMessage] = useState('');
  const [acceptedCerts, setAcceptedCerts] = useState(notification.tanksAndCertificates);
  const cellarIdMap = useMemo(() => {
    const map = new Map();

    cellars.forEach((cellar) => {
      map.set(cellar.name, cellar.id);
    });

    return map;
  }, [cellars]);

  const getCellars = useCallback(async () => {
    const cellarsQuery = query(collection(
      firestore,
      `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/cellars`,
    ));
    const querySnapshot = await getDocs(cellarsQuery);
    const cellarDocs = querySnapshot.docs;
    const existingCellarsArray = cellarDocs.map((cel) => ({ name: cel.data().name, id: cel.id }));
    const existingCellarsNames = new Set(existingCellarsArray.map((cel) => cel.name));
    setCellars(existingCellarsArray);
    const newCellarsList = notification.tanksAndCertificates
      .map((cert) => {
        const exists = existingCellarsNames.has(cert.cellarName);
        const celObj = {
          name: cert.cellarName,
          id: exists ? cert.cellarId : uuidv4(),
        };
        return celObj;
      })
      .filter((cert) => !existingCellarsNames.has(cert.name));
    setNewCellars(newCellarsList);

    setPageStatus('loaded');
  }, [firestore, globalState.activeOrganization,
    notification.tanksAndCertificates]);

  useEffect(() => {
    if (pageStatus === 'initial' && globalState.activeOrganization !== '') {
      setPageStatus('loading');
      getCellars();
    }
  }, [getCellars, globalState.activeOrganization, pageStatus]);

  if (pageStatus !== 'loaded') {
    return <CircularProgress />;
  }

  const handleAccept = async () => {
    setLoading(true);
    try {
      const batch = writeBatch(firestore);
      const uniqueNewCellars = Array.from(new Set(newCellars));
      uniqueNewCellars.forEach(({ id, name }) => {
        const docRef = doc(firestore, `${globalState.clientPath}/cellars`, id);
        batch.set(docRef, { id, name });
      });

      await batch.commit();

      const acceptedCertificates = acceptedCerts.map((cert) => {
        const cellarId = cellarIdMap.get(cert.cellarName) || uniqueNewCellars.find(
          (cellar) => cellar.name === cert.cellarName,
        )?.id;
        return { ...cert, cellarId };
      });
      await acceptNewCertificatesCall({
        notifiedOrganizationId: notification.notifiedOrganizationId,
        acceptedCertificates,
        gaugerOrganizationId: notification.gaugerOrganizationId,
        certificatesType: notification.certificatesType,
        rejectedCertificates: rejectedCerts,
        rejectMessage,
      });
      await deleteDoc(doc(
        firestore,
        `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/notifications`,
        notification.NO_ID_FIELD || notification.id,
      ));
      const cacheKey = `digitank::tanksAndCellars::lastQueryTime::${globalState.activeOrganization}`;
      sessionStorage.removeItem(cacheKey);
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
    } catch (e) {
      console.error(e);
      globalActions.setSnackbarMessage({ message: e.message, severity: 'error' });
    } finally {
      handleCloseDetail();
    }
  };

  const handleDelete = async () => {
    await deleteDoc(doc(
      firestore,
      `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/notifications`,
      notification.NO_ID_FIELD || notification.id,
    ));
    handleCloseDetail();
  };

  const handleRejectMessageChange = (e) => {
    const message = e.target.value;
    setRejectMessage(message);
  };

  const onUpdateCellars = (cellarId, isAccepted, newAcceptedCellars) => {
    const cellarIsNew = newCellars.includes(cellarId);
    if (isAccepted && cellarIsNew) {
      // If a previously rejected certificate is accepted and its cellar is new,
      // do nothing since the cellar is already in newCellars.
      return;
    }
    if (!isAccepted && cellarIsNew) {
      // If a certificate is rejected and its cellar is new, check if
      // there are other non-rejected certificates with the same cellarId.
      const otherCertsWithSameCellar = newAcceptedCellars.filter((cert) => cert.cellarId === cellarId);
      if (otherCertsWithSameCellar.length === 0) {
        setNewCellars((prevNewCellars) => prevNewCellars.filter((id) => id !== cellarId));
      }
    }
    if (isAccepted && !cellarIsNew) {
      // If a previously rejected certificate is accepted and its cellar isn't new,
      // check if it should be added to newCellars.
      const existingCellarsSet = new Set(cellars);
      if (!existingCellarsSet.has(cellarId)) {
        setNewCellars((prevNewCellars) => [...prevNewCellars, cellarId]);
      }
    }
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', p: 3, gap: 2,
    }}
    >
      <Typography variant="h6">
        {`${t(`notifications.${notification.notificationType}Text`)}`}
      </Typography>
      <List sx={{ width: '100%' }}>
        {acceptedCerts.map((cert, i) => (
          <div key={cert.certificateId}>
            <NewCertificateItem
              key={cert.certificateId}
              item={cert}
              rejectedCerts={rejectedCerts}
              setRejectedCerts={setRejectedCerts}
              setAcceptedCerts={setAcceptedCerts}
              onUpdateCellars={onUpdateCellars}
              acceptedCerts={acceptedCerts}
            />
            <Divider />
          </div>
        ))}
      </List>
      {rejectedCerts.length > 0 && (
        <RejectedCertificates
          rejectedCerts={rejectedCerts}
          setRejectedCerts={setRejectedCerts}
          setAcceptedCerts={setAcceptedCerts}
          handleRejectMessageChange={handleRejectMessageChange}
          rejectMessage={rejectMessage}
          onUpdateCellars={onUpdateCellars}
          acceptedCerts={acceptedCerts}
        />
      )}

      {newCellars.length > 0 && (
        <NewCellars newCellars={newCellars.map((cel) => cel.name)} cellars={cellars.map((cel) => cel.name)} />
      )}

      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-end',
      }}
      >
        <Button sx={{ alignSelf: 'flex-start' }} onClick={handleCloseDetail}>
          {' '}
          {t('close')}
        </Button>
        <LoadingButton variant="outlined" color="error" disabled>
          {t('refuse')}
        </LoadingButton>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleAccept}>
          {t('accept')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

function NewCellars({ newCellars, cellars }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{t('accept_certificates.new_cellars')}</Typography>
      <Typography sx={{ mb: 1 }}>{Array.from(new Set(newCellars)).join(', ')}</Typography>

      <Typography>{t('accept_certificates.existing_cellars')}</Typography>
      <Typography>{cellars.join(', ')}</Typography>
    </Box>
  );
}
