import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
// import TableSortLabel from '@mui/material/TableSortLabel';

export default function TankListHeaders() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography>
        {t('admin.tank_id')}
      </Typography>
      <Typography>
        {t('admin.cellar')}
      </Typography>
      <Typography sx={{ width: 200 }}>
        {t('admin.last_modified')}
        {/* <TableSortLabel
          active={sortBy === 'lastModified'}
          direction={sortOrder}
          onClick={() => handleSort('lastModified')}
        /> */}
      </Typography>
    </Box>
  );
}
