import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { LoadingButton } from '@mui/lab';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { preloadCellarTanksAndCertificates } from './queries';

function CellarSelect({
  cellar, handleChangeCellar, cellarsAndTanks, tanksOfflineStatus, checkCellarTanksOffline,
}) {
  const { t } = useTranslation();
  const db = useFirestore();
  const [loading, setLoading] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();

  const statusValues = Object.values(tanksOfflineStatus);

  const handlePreload = async () => {
    setLoading(true);
    try {
      await preloadCellarTanksAndCertificates(db, cellar, globalState);
      checkCellarTanksOffline(cellar, cellarsAndTanks);
    } catch (e) {
      if (globalState.networkState === 'offline') {
        globalActions.setSnackbarMessage({ message: t('functionality_requires_connection'), severity: 'warning' });
      } else {
        logEvent(analytics, 'error_pre_loading_cellar_tanks', {
          organization: globalState.activeOrganization,
          error_message: e.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const renderIcon = () => {
    const allTanksOfflineAvailable = statusValues.every((status) => status === 'inCache');
    const someTanksInitial = statusValues.some((status) => status === 'initial');

    if (allTanksOfflineAvailable) {
      return <SecurityUpdateGoodIcon sx={{ fontSize: 18, color: 'primary.main' }} />;
    } if (someTanksInitial) {
      return <CircularProgress size={18} />;
    }
    return (
      <LoadingButton color="darkgray" aria-label="download-cellar" onClick={handlePreload} loading={loading}>
        <DownloadingIcon sx={{ fontSize: 28, color: 'gray' }} />
      </LoadingButton>
    );
  };

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', gap: 1, alignItems: 'center',
    }}
    >
      <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
        <InputLabel id="cellar-select-label">
          {t('tank_selection.cellar')}
        </InputLabel>
        <Select
          labelId="cellar-select-label"
          id="cellar-select"
          value={cellar}
          label={t('tank_selection.cellar')}
          onChange={handleChangeCellar}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
          renderValue={(selectedCellar) => (
            <Box sx={{
              display: 'flex', gap: 1, justifyContent: 'space-between', width: '100%',
            }}
            >
              {cellarsAndTanks[selectedCellar].name}
            </Box>
          )}
        >
          {Object.keys(cellarsAndTanks).map((u, i) => (
            <MenuItem key={u} value={u}>
              {cellarsAndTanks[u].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {renderIcon()}
    </Box>

  );
}

export default CellarSelect;
