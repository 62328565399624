import * as React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export default function TankListItem(props) {
  const {
    index, handleOpen, tank,
  } = props;
  return (
    <ListItem key={index} component="div">
      <ListItemButton sx={{ py: 2 }} onClick={() => handleOpen(tank)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>{tank.name}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>{tank.cellarName}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'end' }}>
            {new Date(tank.lastModified.toMillis()).toLocaleString()}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
