import React from 'react';
import {
  dynamicUnitName,
} from 'components/units/UnitDefinitionsAndMasks';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

export default function OperationVolumeConversion(props) {
  const { batchResults } = props;
  const { t } = useTranslation();
  const [displayAll, setdisplayAll] = React.useState(false);

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        display: 'flex',
        gap: 3,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => setdisplayAll(!displayAll)}
      >
        <Typography>{t('volume_conversion.results')}</Typography>
        <IconButton>
          {expandIcon()}
        </IconButton>
      </Box>
      <Collapse
        in={displayAll}
        sx={{
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: 1,
          pb: 3,
        }}
        >
          {batchResults && Object.entries(batchResults).map((batchResult, index) => (
            <div key={batchResult[0]}>
              <BatchResult
                batchNumber={batchResult[0]}
                batchResult={batchResult[1]}
              />
            </div>
          ))}
        </Box>

      </Collapse>

    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}

function BatchResult(props) {
  const { batchResult, batchNumber } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
    }}
    >
      <Box sx={{
        alignSelf: 'flex-start',
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography component="span" sx={{ fontWeight: 'bold', color: batchResult.color }}>
          {t('volume_conversion.batch_number')}
          {batchNumber}
        </Typography>
      </Box>
      <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }} component="span" variant="caption">
        {t('operation.compartments')}
        {': '}
        {batchResult.compartmentIds.join(', ')}
        <br />
        {t(
          'volume_conversion.at_temperature',
          {
            temperature: batchResult.requestedValues.temperature,
            temperatureUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
          },
        )}
        <br />
        {t('volume_conversion.tav_result_short', {
          tav: batchResult.requestedValues.tav,
          alcoholUnit: dynamicUnitName(batchResult.requestedValues.alcoholUnit),
          referenceTempValue: batchResult.requestedValues.tavTemperature,
          referenceTempUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
        })}
      </Typography>
      <BatchResultDetail batchResult={batchResult} />
    </Box>
  );
}

function BatchResultDetail(props) {
  const { batchResult } = props;
  const { t } = useTranslation();
  const pureAlcoholResult = batchResult.pureAlcoholVolumeAtReferenceTemperature;
  const volumeResult = batchResult.convertedVolume;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      <Typography component="span">
        {t('forms.correction_factor')}
        {': '}
        {batchResult.conversionCoefficient.value}
      </Typography>
      <Typography component="span">
        {t('forms.volume_at_temp')
    + volumeResult.temperatureValue
    + temperatureUnitString(volumeResult.temperatureUnit)}
        {': '}
        {`${volumeResult.value} ${dynamicUnitName(batchResult.requestedValues.volumeUnit)}`}
      </Typography>
      <Typography component="span">
        {t('forms.pure_alcohol', {
          referenceTempValue: pureAlcoholResult.temperatureValue,
          referenceTempUnit: dynamicUnitName(
            pureAlcoholResult.temperatureUnit,
          ),
        })}
        {': '}
        {`${pureAlcoholResult.value} ${dynamicUnitName(batchResult.requestedValues.volumeUnit)}`}
      </Typography>

    </Box>
  );
}
