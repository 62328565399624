import React, {
  createContext, useContext, useMemo,
} from 'react';
import { getFunctions } from 'firebase/functions';

const FunctionsContext = createContext();

export function FunctionsProvider({ app, children }) {
  const functions = getFunctions(app);

  const value = useMemo(() => functions, [functions]);

  return (
    <FunctionsContext.Provider value={value}>
      {children}
    </FunctionsContext.Provider>
  );
}

export function useFunctions() {
  return useContext(FunctionsContext);
}
