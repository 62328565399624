import React from 'react';
import {
  Paper, Typography, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GaugerCollaboration from 'components/admin/gaugerCollaboration/GaugerCollaboration';

function ManualBillingSubscriptionPage() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        gap: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '800px',
          gap: 3,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t('subscription.manual_billing_page_title')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t('subscription.manual_billing_page_info')}
        </Typography>
      </Paper>
      <GaugerCollaboration />
    </Box>
  );
}

export default ManualBillingSubscriptionPage;
