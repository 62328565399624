import React, {
  useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { unit } from 'mathjs';
import { unitFormatPrecision, unitMasks } from 'components/units/UnitDefinitionsAndMasks';
import {
  round, searchInCompartmentArray, roundNoFixed, nominalVolumeFromCompartment,
} from 'components/utils';
import useGlobal from 'global-state/store';
import ResultCard from 'components/ResultCard';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import useMenu from 'menu-actions/useMenu';
import { v4 as uuidv4 } from 'uuid';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import CompartmentField from './CompartmentField';
import validationSchema from './CompartmentValidationSchema';
import OperationButtons from './OperationButtons';
import APVolumeButton from './ap-vol-conversion/APVolumeButton';
import ExtraOperationFields from './ExtraOperationFiels';
import loadLastOperation from './loadLastOperation';

export default function Compartment(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    compartment, tankNumber, certificatePath, cellar, dispatchMenuActions, certificatePdfPath,
    tankName, cellarName, certificateName,
  } = props;
  const [globalState, globalActions] = useGlobal();
  const [apKey, setApKey] = useState(uuidv4());
  const { setreturnAction } = useMenu();
  useEffect(() => {
    setreturnAction(() => () => navigate('/'));
  }, [navigate, setreturnAction]);
  const volumeUnit = globalState.orgUnits.volume;
  const firestore = useFirestore();

  const [highlightment, setHighlightment] = useState({
    height: false,
    volume: false,
  });
  const fieldUtils = useMemo(() => ({
    height: {
      unitLabel: 'mm',
      unitCompute: 'm',
      searchUnitLabel: volumeUnit,
      searchUnitCompute: 'L',
      conversion: compartment.volumeToHeight,
      arrayToSearch: Object.keys(compartment.volumeToHeight)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
    },
    volume: {
      unitLabel: volumeUnit,
      unitCompute: 'L',
      searchUnitLabel: 'mm',
      searchUnitCompute: 'm',
      conversion: compartment.heightToVolume,
      arrayToSearch: Object.keys(compartment.heightToVolume)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
    },
  }), [compartment.heightToVolume, compartment.volumeToHeight, volumeUnit]);
  const operationItemId = `last-operation::${cellar}::${tankNumber}`;

  const [fields, setFields] = useState(initFields());
  const [extraFields, setExtraFields] = useState([]);

  function initFields() {
    const queryParameters = new URLSearchParams(window.location.search);
    const form = queryParameters.get('form');
    if (form) {
      return JSON.parse(form).fields;
    }
    return {
      height: {
        error: null,
        value: '',
      },
      volume: {
        error: null,
        value: '',
      },
    };
  }

  const [volumeToAdd, setVolumeToAdd] = useState(initVolumeToAdd());

  function initVolumeToAdd() {
    const queryParameters = new URLSearchParams(window.location.search);
    const form = queryParameters.get('form');
    if (form) {
      return JSON.parse(form).volumeToAdd;
    }
    return {
      error: null,
      value: '',
    };
  }

  const initResults = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const form = queryParameters.get('form');
    if (form) {
      return JSON.parse(form).results;
    }
    return { volume: '-', height: '-', volumeConvResult: {} };
  };

  const [results, setResults] = useState(initResults());

  const [volumeToAddTab, setvolumeToAddTab] = useState(initvolumeToAddTab());

  function initvolumeToAddTab() {
    const savedTab = localStorage.getItem('last_operation_volume_to_add_tab');
    if (!savedTab) {
      return 'add';
    }
    return savedTab;
  }

  const minAndMaxes = {
    minHeight: roundNoFixed(unit(compartment.minHeight, 'm').toNumber('mm'), 4),
    maxHeight: roundNoFixed(unit(compartment.maxHeight, 'm').toNumber('mm'), 4),
    minVolume: roundNoFixed(unit(compartment.minVolume, 'L').toNumber(volumeUnit), 4),
    maxVolume: roundNoFixed(unit(compartment.maxVolume, 'L').toNumber(volumeUnit), 4),
  };

  const schema = validationSchema(
    t,
    minAndMaxes.minHeight,
    minAndMaxes.maxHeight,
    minAndMaxes.minVolume,
    minAndMaxes.maxVolume,
  );

  const removeHighlight = (fieldname) => {
    setHighlightment({
      ...highlightment,
      [fieldname]: false,
    });
  };

  const updateForm = async (value, name) => {
    const innerForm = { [name]: value };
    const field = fields[name];
    const otherFieldName = Object.keys(fields).find((fname) => fname !== name);
    try {
      await schema.validate(innerForm);
      innerForm[otherFieldName] = convert(
        value,
        fieldUtils[otherFieldName].arrayToSearch,
        fieldUtils[otherFieldName].conversion,
        fieldUtils[otherFieldName].searchUnitLabel,
        fieldUtils[otherFieldName].searchUnitCompute,
        fieldUtils[otherFieldName].unitLabel,
        fieldUtils[otherFieldName].unitCompute,
      );
      setFields({
        [name]: { ...field, error: null, value },
        [otherFieldName]: { ...field, error: null, value: innerForm[otherFieldName] },
      });
      setHighlightment({
        [name]: false,
        [otherFieldName]: true,
      });
      setTimeout(removeHighlight, 300, otherFieldName);
      setResults({
        [name]: value,
        [otherFieldName]: innerForm[otherFieldName],
      });
      setVolumeToAdd({ value: '', error: null });
    } catch (err) {
      setFields({
        ...fields,
        [name]: { ...field, error: err.message },
      });
      setResults(initResults);
      setVolumeToAdd({ value: '', error: null });
    }
  };

  const updateResults = async (value, name) => {
    let finalVolume;
    if (volumeToAddTab === 'add') {
      finalVolume = Number(fields.volume.value) + Number(value);
    } else {
      finalVolume = Number(fields.volume.value) - Number(value);
    }
    finalVolume = round(finalVolume, unitMasks[fieldUtils.volume.unitLabel]);
    const innerFinalForm = { volume: finalVolume };
    try {
      await schema.validate(innerFinalForm);
      const finalHeight = convert(
        finalVolume,
        fieldUtils.height.arrayToSearch,
        fieldUtils.height.conversion,
        fieldUtils.height.searchUnitLabel,
        fieldUtils.height.searchUnitCompute,
        fieldUtils.height.unitLabel,
        fieldUtils.height.unitCompute,
      );
      setResults({
        height: finalHeight,
        volume: finalVolume,
      });
      setVolumeToAdd({ value, error: null });
    } catch (err) {
      let errorMessage = err.message;
      if (finalVolume > minAndMaxes.maxVolume) {
        errorMessage = t('operation.final_volume_too_high', { maxVolume: minAndMaxes.maxVolume });
      } else if (finalVolume < minAndMaxes.minVolume) {
        errorMessage = t('operation.final_volume_too_low', { minVolume: minAndMaxes.minVolume });
      }
      setVolumeToAdd({ value, error: errorMessage });
      setResults(initResults);
    }
  };

  function convert(
    value,
    arrayToSearch,
    conversion,
    inputUnitLabel,
    inputUnitCompute,
    outputUnitLabel,
    outputUnitCompute,
  ) {
    if (value === null || value === '') {
      return '';
    }
    const inputValue = round(
      unit(value, inputUnitLabel).toNumber(inputUnitCompute),
      unitFormatPrecision,
    );
    const outputValue = searchInCompartmentArray(
      inputValue,
      arrayToSearch,
      conversion,
    );
    return round(
      unit(outputValue, outputUnitCompute).toNumber(outputUnitLabel),
      unitMasks[outputUnitLabel],
    );
  }

  useEffect(() => {
    const loadLastOperationAction = {
      name: t('operation.load_last_operation'),
      callback: () => {
        loadLastOperation(
          tankNumber,
          operationItemId,
          setFields,
          setResults,
          setVolumeToAdd,
          setApKey,
          globalState,
          globalActions,
          t,
          firestore,
        );
      },
    };
    const handleGoToPDFViewer = () => {
      if (globalState.networkState === 'offline') {
        globalActions.setSnackbarMessage({ message: t('functionality_requires_connection'), severity: 'warning' });
      } else {
        navigate(`/pdfViewer?filePath=${encodeURIComponent(certificatePdfPath)}`);
      }
    };
    const goToCertifPdfAction = {
      name: t('storage.certificate_pdf'),
      callback: handleGoToPDFViewer,
    };
    dispatchMenuActions({ type: 'add', actions: [loadLastOperationAction, goToCertifPdfAction] });
  }, [dispatchMenuActions, certificatePath, navigate, t, globalActions, cellar, tankNumber,
    operationItemId, certificatePdfPath, globalState.networkState, globalState, firestore]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
      onSubmit={handleSubmit}
    >
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'stretch',
          justifyContent: 'center',
          width: '100%',
        }}
        onSubmit={handleSubmit}
      >
        <Paper
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Typography
            sx={{ width: '50%' }}
            variant="subtitle2"
            gutterBottom
            component="div"
          >
            {t('operation.nominal_volume', { unit: 'HL' })}
            {': '}
            {nominalVolumeFromCompartment(compartment, t)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CompartmentField
              label={t('operation.height')}
              name="height"
              error={fields.height.error}
              inputMode="numeric"
              decimalScale={0}
              updateForm={updateForm}
              value={fields.height.value}
              highlighted={highlightment.height}
            />
            <CompartmentField
              label={t('operation.volume', { unit: volumeUnit })}
              name="volume"
              error={fields.volume.error}
              decimalScale={4}
              updateForm={updateForm}
              value={fields.volume.value}
              highlighted={highlightment.volume}
            />
          </Box>
        </Paper>
        <Paper
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <TabContext value={volumeToAddTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1.5 }}>
              <TabList
                onChange={(event, newValue) => {
                  setvolumeToAddTab(newValue);
                  setResults(initResults);
                  setVolumeToAdd({ value: '', error: null });
                  localStorage.setItem('last_operation_volume_to_add_tab', newValue);
                }}
                centered
              >
                <Tab label={t('operation.add_volume')} value="add" />
                <Tab label={t('operation.remove_volume')} value="remove" />
              </TabList>
            </Box>
            <CompartmentField
              label={t('operation.volume', { unit: volumeUnit })}
              name={volumeToAdd.name}
              error={volumeToAdd.error}
              decimalScale={4}
              value={volumeToAdd.value}
              updateForm={updateResults}
            />
          </TabContext>
        </Paper>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'row wrap',
            gap: 1,
            alignItems: 'stretch',
            justifyContent: 'center',
            mb: 3,
          }}
        >
          <ResultCard
            title={t('operation.final_volume')}
            result={`${results.volume} ${volumeUnit}`}
            maxWidth={340}
          />

          <ResultCard
            title={t('operation.final_height')}
            result={`${results.height} mm`}
            maxWidth={340}
          />
        </Box>
        <APVolumeButton
          volumeUnit={volumeUnit}
          key={apKey}
          apResult={results.volumeConvResult}
          setApResult={(res) => setResults({ ...results, volumeConvResult: res })}
          volumeValue={results.volume}
          initMeasuredTav={results.volumeConvResult?.measuredTav?.value || ''}
          initTavTemperature={results.volumeConvResult?.tavTemperature?.value || ''}
          initLiquidTemperature={results.volumeConvResult?.liquidTemperature?.value || ''}
        />
        <ExtraOperationFields setExtraFields={setExtraFields} tankId={tankNumber} />
        <OperationButtons
          form={{
            volumeToAdd,
            fields,
            extraFields,
            volumeUnit,
            results,
            nominalVolume: compartment.nominalVolume,
            volumeChange: volumeToAddTab,
          }}
          tankNumber={tankNumber}
          certificatePath={certificatePath}
          cellar={cellar}
          tankName={tankName}
          cellarName={cellarName}
          certificateName={certificateName}
        />
      </Box>
    </Box>
  );
}
