import React, { createContext, useContext, useMemo } from 'react';
import { getStorage } from 'firebase/storage';

const StorageContext = createContext(null);

export function StorageProvider({ app, children }) {
  const storage = getStorage(app);

  const value = useMemo(() => storage, [storage]);

  return (
    <StorageContext.Provider value={value}>
      {children}
    </StorageContext.Provider>
  );
}

export const useStorage = () => useContext(StorageContext);
