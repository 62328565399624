import React, { useState } from 'react';
import { useFormik } from 'formik';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { NumberInput } from 'components/Inputs';
import useGlobal from 'global-state/store';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import decimalsMask from './DecimalsMask';
import validationSchema from './ValidationSchema';
import AlcoholFormTabs from './AlcoholFormTabs';
import { LabelInput } from './BoxetteInputs';

export default function AlcoConversionForm({
  setApResult, volumeUnit, volumeValue, initMeasuredTav = '',
  initTavTemperature = '', initLiquidTemperature = '', handleClose,
}) {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [globalState, globalActions] = useGlobal();

  const submit = async (values) => {
    try {
      setLoading(true);
      const laboxAlcoholometryApiCall = httpsCallable(functions, 'laboxAlcoholometryApiCall2ndGen');
      let abvAt20cValue;

      if (globalState.networkState === 'offline') {
        setApResult({
          measuredTav: {
            value: values.measuredTav,
            unit: 'TAV',
            precision: decimalsMask.TAV,
          },
          tavTemperature: {
            value: values.tavTemperature,
            unit: 'celsius',
            precision: decimalsMask.celsius,
          },
          liquidTemperature: {
            value: values.liquidTemperature,
            unit: 'celsius',
            precision: decimalsMask.celsius,
          },
        });
        handleClose();
        return;
      }

      if (!/^20(\.0{1,2})?$/.test(values.tavTemperature)) {
        const resTAVConversion = await laboxAlcoholometryApiCall({
          call: 'convertABVToTemperature',
          form: {
            abvValue: values.measuredTav,
            abvUnit: 'TAV',
            abvOutUnit: 'TAV',
            temperatureInValue: values.tavTemperature,
            temperatureOutValue: '20',
            temperatureInUnit: 'celsius',
            temperatureOutUnit: 'celsius',
            abvPrecision: decimalsMask.TAV,
            temperatureInPrecision: decimalsMask.celsius,
          },
          app: 'digitank-gauged-containers',
        });
        abvAt20cValue = resTAVConversion.data.result.ABVAt20c.value;
      } else {
        abvAt20cValue = values.measuredTav;
      }
      const response = await laboxAlcoholometryApiCall({
        call: 'convertVolumeToVolumeAtTemp',
        form: {
          abvAt20cValue,
          abvAt20cUnit: 'TAV',
          volumeValue,
          volumeUnit,
          temperatureInValue: values.liquidTemperature,
          temperatureOutValue: '20',
          temperatureInUnit: 'celsius',
          temperatureOutUnit: 'celsius',
          abvPrecision: decimalsMask.TAV,
          volumePrecision: decimalsMask[volumeUnit],
          temperatureInPrecision: decimalsMask.celsius,
          intermediateRounding: true,
        },
        app: 'digitank-gauged-containers',
      });
      setApResult({
        ...response.data.result,
        abvAt20c: {
          value: abvAt20cValue,
          unit: 'TAV',
          precision: decimalsMask.TAV,
        },
        measuredTav: {
          value: values.measuredTav,
          unit: 'TAV',
          precision: decimalsMask.TAV,
        },
        tavTemperature: {
          value: values.tavTemperature,
          unit: 'celsius',
          precision: decimalsMask.celsius,
        },
        liquidTemperature: {
          value: values.liquidTemperature,
          unit: 'celsius',
          precision: decimalsMask.celsius,
        },
      });
      handleClose();
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      measuredTav: initMeasuredTav,
      tavTemperature: initTavTemperature,
      liquidTemperature: initLiquidTemperature,
    },
    validationSchema: validationSchema(t),
    onSubmit: submit,
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center',
    }}
    >
      <AlcoholFormTabs formik={formik} />
      <Box sx={{ width: '100%', maxWidth: 400, mb: 2 }}>
        <LabelInput
          label={
            <Typography component="span">{t('temperatureInValue')}</Typography>
        }
          input={(
            <NumberInput
              onChange={formik.handleChange}
              name="liquidTemperature"
              placeholder="ex: 24"
              value={formik.values.liquidTemperature}
              decimalScale={decimalsMask.celsius}
              onBlur={formik.handleBlur}
              allowNegative
            />
        )}
          error={formik.touched.liquidTemperature && formik.errors.liquidTemperature && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.liquidTemperature}
          </Typography>
          )}
          select={(
            <Typography>
              % vol.
            </Typography>
      )}
        />
      </Box>

      <LoadingButton
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
        onClick={formik.handleSubmit}
        disabled={globalState.networkState === 'offline'}
      >
        {t('forms.calcul')}
      </LoadingButton>
      {globalState.networkState === 'offline' && (
        <Typography variant="caption">
          {(t('operation.offline_save_warning'))}
        </Typography>
      )}
      {globalState.networkState === 'offline' && (
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
        >
          {t('operation.save_title')}
        </Button>
      )}
    </Box>
  );
}
