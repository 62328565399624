import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import SocialSigninCard from './SocialSignInCard';
import CreateAccountForm from './CreateAccountForm';

export default function CreateAccountContent(props) {
  const {
    t, globalActions, navigate, Link, auth,
    analytics, gtcuCookieName, ourSignInWithPopup, signinCallback,
    createUserWithEmailAndPassword, sendEmailVerification,
    logEvent,
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <SocialSigninCard
        createAccount
        t={t}
        analytics={analytics}
        navigate={navigate}
        Link={Link}
        auth={auth}
        gtcuCookieName={gtcuCookieName}
        ourSignInWithPopup={ourSignInWithPopup}
        signinCallback={signinCallback}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 3,
          px: 1,
          gap: 2,
          border: 1,
          borderRadius: 1,
          width: { xs: '100%', sm: '88%' },
          maxWidth: 670,
        }}
      >
        <Typography component="span" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {t('signin.create_account')}
        </Typography>
        <CreateAccountForm
          auth={auth}
          navigate={navigate}
          t={t}
          Link={Link}
          analytics={analytics}
          gtcuCookieName={gtcuCookieName}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          sendEmailVerification={sendEmailVerification}
          logEvent={logEvent}
          globalActions={globalActions}
          signinCallback={signinCallback}
        />
      </Box>
    </Box>
  );
}
