import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export default function OperationListItem(props) {
  const {
    index, handleOpen, operation,
  } = props;

  return (
    <ListItem key={index} component="div">
      <ListItemButton sx={{ py: 1 }} onClick={() => handleOpen(operation)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>{operation.tankName || operation.tankNumber}</Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1 }}>
            {`${operation.cellarName || operation.cellar}`}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'end' }}>
            {new Date(operation.dateAndTime.toMillis()).toLocaleString()}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
