import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AlcoConversionTAVForm from './AlcoConversionTAVForm';
import AlcoConversionElecDensimeterForm from './AlcoConversionElecDensimeterForm';

export default function AlcoholFormTabs(props) {
  const {
    formik,
  } = props;
  const [tab, setTab] = useState(initTab());
  const { t } = useTranslation();

  function initTab() {
    const savedTab = localStorage.getItem('last_alco_conversion_tab');
    if (!savedTab) {
      return 'tav';
    }
    return savedTab;
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('last_alco_conversion_tab', newValue);
    if (newValue === 'elecdensimeter') {
      formik.setFieldValue('tavTemperature', '20.0');
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      width: ' 100%',
    }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered>
            <Tab label={t('alcohol_form.with_elec_densimeter')} value="elecdensimeter" />
            <Tab label={t('alcohol_form.with_tav')} value="tav" />
          </TabList>
        </Box>
        <TabPanel value="tav" sx={{ p: 0 }}>
          <AlcoConversionTAVForm
            formik={formik}
          />
        </TabPanel>
        <TabPanel value="elecdensimeter" sx={{ p: 0 }}>
          <AlcoConversionElecDensimeterForm
            formik={formik}
          />
        </TabPanel>
      </TabContext>

    </Box>

  );
}
