import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link as MUILink } from '@mui/material';
import { Formik } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import AcceptGTCU from './AcceptGTCU';

export default function EmailPasswordLoginForm(props) {
  const {
    setemailPasswordCardType, afterSignInRedirect, auth, signinCallback = () => {},
    signInWithEmailAndPassword, navigate, t, globalActions, Link, gtcuCookieName,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [acceptedGTCU, setacceptedGTCU] = useState(false);
  const [showGTCUError, setshowGTCUError] = useState(false);
  const cookies = new Cookies();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function signIn(email, password, setSubmitting) {
    if (!acceptedGTCU) {
      return setshowGTCUError(true);
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      signinCallback();
      if (afterSignInRedirect) {
        navigate(afterSignInRedirect);
      }
    } catch (err) {
      globalActions.setSnackbarMessage({ message: err.message, severity: 'error' });
    } finally {
      setSubmitting(false);
    }
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = t('validations.required');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t('validations.email_invalid');
          }
          if (!values.password) {
            errors.password = t('validations.required');
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // storing on both localStorage(stays longer) and cookies (sent to server) just in case
          localStorage.setItem(gtcuCookieName, 'true');
          cookies.set(gtcuCookieName, 'true');
          await signIn(values.email, values.password, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  width: '100%',
                  gap: 1,
                }}
              >
                <TextField
                  style={{ maxWidth: 300 }}
                  fullWidth
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  label={t('signin.email')}
                  error={touched.email && Boolean(errors.email)}
                  helperText={errors.email && touched.email && errors.email}
                />
                <TextField
                  style={{ maxWidth: 300 }}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  label={t('signin.password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <MUILink
                sx={{ cursor: 'pointer' }}
                onClick={() => setemailPasswordCardType('passwordForgotten')}
                color="secondary"
              >
                {t('signin.password_forgotten')}
              </MUILink>

              <AcceptGTCU
                acceptedGTCU={acceptedGTCU}
                setacceptedGTCU={setacceptedGTCU}
                showGTCUError={showGTCUError}
                setshowGTCUError={setshowGTCUError}
                t={t}
                Link={Link}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {t('signin.login')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
