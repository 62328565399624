import { getCertifData, getMostRecentValidation } from 'components/operation/tankQueries';
import {
  collection, doc, getDoc, getDocFromCache, getDocs, getDocsFromCache, query, where,
} from 'firebase/firestore';

const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

export async function getCellarIdsAndTankIds(
  firestore,
  path,
  globalState,
  forceCacheReload = false,
) {
  const cellarsQuery = query(
    collection(firestore, path, 'cellars'),
  );
  const cacheKey = `digitank::tanksAndCellars::lastQueryTime::${globalState.activeOrganization}`;
  const lastQueryTime = sessionStorage.getItem(cacheKey);
  const now = Date.now();

  const loadFromCache = !forceCacheReload && lastQueryTime && (now - parseInt(lastQueryTime, 10) < CACHE_DURATION);

  let querySnapshot;
  // console.log('LOAD FROM CACHE', loadFromCache);
  if (loadFromCache) {
    querySnapshot = await getDocsFromCache(cellarsQuery);
  } else {
    querySnapshot = await getDocs(cellarsQuery);
  }

  const cellarDocs = querySnapshot.docs;
  const newCellarsAndTanks = {};

  await Promise.all(cellarDocs.map(async (cel) => {
    const cellarTanks = await getTankIds(
      cel.ref.firestore,
      cel.id,
      globalState,
      loadFromCache,
    );
    newCellarsAndTanks[cel.id] = { name: cel.data().name, tanks: cellarTanks };
  }));

  if (!querySnapshot.metadata.fromCache) {
    sessionStorage.setItem(cacheKey, now.toString());
  }

  return newCellarsAndTanks;
}

export function initializeCachedTanks(newCellarsAndTanks, setTanksOfflineStatus) {
  const newTanksOfflineStatus = {};
  for (const cellarId in newCellarsAndTanks) {
    if (Object.prototype.hasOwnProperty.call(newCellarsAndTanks, cellarId)) {
      newCellarsAndTanks[cellarId].tanks.forEach((ct) => {
        newTanksOfflineStatus[ct.id] = 'initial';
      });
    }
  }
  setTanksOfflineStatus(newTanksOfflineStatus);
}

async function getTankIds(firestore, cellarId, globalState, fromCache = false) {
  const tanksQuery = query(collection(
    firestore,
    `${globalState.clientPath}/tanks`,
  ), where('cellar', '==', cellarId));

  let querySnapshot;
  if (fromCache) {
    querySnapshot = await getDocsFromCache(tanksQuery);
  } else {
    querySnapshot = await getDocs(tanksQuery);
  }
  const tanksIds = querySnapshot.docs.map((tankDoc) => ({
    id: tankDoc.id,
    name: tankDoc.data().name,
    path: tankDoc.ref.path,
  }));
  return tanksIds;
}

export async function isCertifInCache(db, tankPath) {
  try {
    const tankRef = doc(db, tankPath);
    const tankDoc = await getDocFromCache(tankRef);
    const tankData = tankDoc.data();
    const certifRef = doc(db, tankPath, 'gaugeCertificates', tankData.activeCertificate);
    await getDocFromCache(certifRef);
    return true;
  } catch (err) {
    return false;
  }
}

export async function preloadCellarTanksAndCertificates(db, cellarId, globalState) {
  const tanksQuery = query(collection(
    db,
    `${globalState.clientPath}/tanks`,
  ), where('cellar', '==', cellarId));
  const querySnapshot = await getDocs(tanksQuery);
  const tanksDocs = querySnapshot.docs;

  const promises = tanksDocs.map(async (tankDoc) => {
    const certifRef = doc(db, tankDoc.ref.path, 'gaugeCertificates', tankDoc.data().activeCertificate);
    const certifDoc = await getDoc(certifRef);
    await getCertifData(db, tankDoc.ref.path, certifDoc.data(), () => {});
    await getMostRecentValidation(certifRef.id, globalState.activeOrganization, db);
  });
  await Promise.all(promises);
}
