import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import Typography from '@mui/material/Typography';
import { downloadCsvFile } from '../../utils';
import OperationDetails from './OperationDetails';
import OperationVolumeConversion from './OperationVolumeConversion';

export default function OperationDetailDialog(props) {
  const { t } = useTranslation();
  const { operation, setOpen, open } = props;
  const [compartmentCsv, setcompartmentCsv] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    function formatCompartmentForCsv(op) {
      return {
        operationId: op.id,
        [t('operation_csv_headers.tankNumber')]: op.tankName || op.tankNumber,
        [t('operation_csv_headers.height')]: op.form.fields.height.value,
        [t('operation_csv_headers.volume', { unit: op.form.volumeUnit || 'HL' })]: op.form.fields.volume.value,
        [t('operation_csv_headers.volumeChange', { unit: op.form.volumeUnit || 'HL' })]: `${op.form.volumeChange
          === 'add' ? '+' : '-'}${op.form.volumeToAdd.value}`,
        [t('operation_csv_headers.finalVolume', { unit: op.form.volumeUnit || 'HL' })]: op.form.results.volume,
        [t('operation_csv_headers.finalHeight')]: op.form.results.height,
        date: new Date(op.dateAndTime.toMillis()).toLocaleString(),
        [t('operation_csv_headers.cellar')]: op.cellarName || op.cellar,
        [t('operation_csv_headers.certificateNumber')]: op.certificateName || op.certificateNumber,
      };
    }
    function setcompartments() {
      const operationCsv = Papa.unparse(
        [formatCompartmentForCsv(operation)],
        {
          delimiter: ';',
        },
      );
      setcompartmentCsv(operationCsv);
    }

    if (operation !== '') {
      setcompartments();
    }
  }, [operation, t]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_selection.tank')}
        {': '}
        {operation.tankName || operation.tankNumber}
      </DialogTitle>
      <DialogContent>
        <OperationDetails operation={operation} />
        {operation.volumeConversionResults
        && (<OperationVolumeConversion batchResults={operation.volumeConversionResults} />)}
        {operation.volumeConversionMethod
        && (
        <Typography component="span">
          {t('settings.volume_calculation_method')}
          {': '}
          {operation.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method'))}
          {operation.volumeConversionMethod !== 'traditional'
         && ('OIML-OIV')}
        </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            downloadCsvFile('compartiment', compartmentCsv);
          }}
        >
          {t('download')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
