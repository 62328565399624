import React, { useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import Grid from '@mui/material/Unstable_Grid2';
import AlcoConversionForm from './AlcoConversionForm';

function APVolumeButton({
  apResult, setApResult, volumeUnit, volumeValue, initMeasuredTav, initTavTemperature, initLiquidTemperature,
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {apResult && Object.keys(apResult).length > 0 && (
      <Paper
        elevation={0}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
          mt: 0,
          mb: 3,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography component="span">
              {apResult.abvAt20c ? t('abv_20c_res', {
                value: apResult.abvAt20c.value,
                unit: dynamicUnitName(apResult.abvAt20c.unit),
              }) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography component="span">
              {t('liquid_temp_res', {
                value: apResult.liquidTemperature.value,
                unit: dynamicUnitName(apResult.liquidTemperature.unit),
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography component="span">
              {apResult.volumeAtTemp ? t('volume_at_temp_res', {
                tempValue: apResult.volumeAtTemp.temperature.value,
                tempUnit: dynamicUnitName(apResult.volumeAtTemp.temperature.unit),
                value: apResult.volumeAtTemp.value,
                unit: dynamicUnitName(apResult.volumeAtTemp.unit),
              }) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography component="span">
              {apResult.pureAlcoholVolumeAt20c ? t('pure_alcohol_res', {
                value: apResult.pureAlcoholVolumeAt20c.value,
                unit: dynamicUnitName(apResult.pureAlcoholVolumeAt20c.unit),
              }) : ''}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      )}

      <Button
        variant="outlined"
        onClick={handleClickOpen}
        disabled={!volumeValue || volumeValue === '-'}
        sx={{ mb: 3 }}
      >
        {t('calculate_ap')}
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle>{t('ap_calculation_title')}</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <AlcoConversionForm
            setApResult={setApResult}
            initLiquidTemperature={initLiquidTemperature}
            initMeasuredTav={initMeasuredTav}
            initTavTemperature={initTavTemperature}
            volumeUnit={volumeUnit}
            handleClose={handleClose}
            volumeValue={volumeValue}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default APVolumeButton;
