import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@mui/material';
import {
  collection, doc, getDoc, getDocs, query, where,
} from 'firebase/firestore';
import useMenu from 'menu-actions/useMenu';
import { useNavigate } from 'react-router-dom';
import CheckoutPage from './CheckoutPage';
import ReloadPage from './ReloadPage';
import ActiveSubscriptions from './ActiveSubscriptions';
import ManualBillingSubscriptionPage from './ManualBillingSubscriptionPage';

export default function Subscription() {
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const { t } = useTranslation();
  const db = useFirestore();
  const [activeSubscriptions, setactiveSubscriptions] = useState([]);
  const [pageStatus, setPageStatus] = useState('initial');
  const { setreturnAction } = useMenu();
  const navigate = useNavigate();

  const delay = (ms) => new Promise(
    // eslint-disable-next-line no-promise-executor-return
    (resolve) => setTimeout(resolve, ms),
  );

  const isManualBilling = useCallback(async () => {
    const appRef = doc(
      db,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'digitank-gauged-containers',
    );
    const appDoc = await getDoc(appRef);
    const { billingMethod } = appDoc.data();
    return billingMethod === 'manual';
  }, [db, globalState.activeOrganization]);

  const lookupActiveSubscriptions = useCallback(async () => {
    const isManual = await isManualBilling();
    if (isManual === true) {
      setPageStatus('showManualBilling');
      return;
    }
    const ownerRef = collection(
      db,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'digitank-gauged-containers',
      'subscriptions',
    );
    const activeSubsQuery = query(ownerRef, where('status', '==', 'active'));
    const activeSubsQuerySnapshot = await getDocs(activeSubsQuery);
    const activeSubs = [];
    activeSubsQuerySnapshot.forEach((fDoc) => {
      activeSubs.push(fDoc.data());
    });
    setactiveSubscriptions(activeSubs);

    const queryParameters = new URLSearchParams(window.location.search);
    const successParam = queryParameters.get('success');
    const sessionIdParam = queryParameters.get('session_id');

    if (activeSubs.length > 0) {
      setPageStatus('showActiveSubs');
    } else if (pageStatus === 'refetchActiveSubs') {
      setPageStatus('shouldShowActiveSubs');
    } else if (successParam
      && sessionIdParam !== ''
      && !['refetchActiveSubs', 'shouldShowActiveSubs', 'showActiveSubs'].includes(pageStatus)) {
      globalActions.setSnackbarMessage({ message: t('subscription.checkout_success'), severity: 'info' });
      await delay(4000);
      setPageStatus('refetchActiveSubs');
    } else if (pageStatus !== 'shouldShowActiveSubs') {
      setPageStatus('showProducts');
    }
  }, [db, globalActions, globalState.activeOrganization, isManualBilling, pageStatus, t]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get('canceled')) {
      globalActions.setSnackbarMessage({ message: t('subscription.order_canceled'), severity: 'error' });
      logEvent(
        analytics,
        'subscription_checkout_canceled',
        {
          appName: 'Digitank, Gauged Containers',
          organization: globalState.activeOrganization,
        },
      );
    }
    setreturnAction(() => () => navigate('/admin'));
  }, [analytics, globalActions, globalState.activeOrganization, navigate, setreturnAction, t]);

  useEffect(() => {
    if (globalState.activeOrganization && ['refetchActiveSubs', 'initial'].includes(pageStatus)) {
      setPageStatus('loading');
      lookupActiveSubscriptions();
    }
  }, [globalState.activeOrganization, isManualBilling, lookupActiveSubscriptions, pageStatus]);

  if (['refetchActiveSubs', 'initial', 'loading'].includes(pageStatus)) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  } if (pageStatus === 'showSignIn') {
    return (
      <Typography component="span" variant="h5" sx={{ my: 3 }}>
        {t('please_sign_in_for_func')}
      </Typography>
    );
  } if (pageStatus === 'showActiveSubs') {
    return <ActiveSubscriptions activeSubscriptions={activeSubscriptions} />;
  } if (pageStatus === 'showManualBilling') {
    return <ManualBillingSubscriptionPage activeSubscriptions={activeSubscriptions} />;
  } if (pageStatus === 'shouldShowActiveSubs') {
    return <ReloadPage message={t('subscription.checkout_success')} />;
  }
  return <CheckoutPage />;
}
