import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { doc, deleteDoc } from 'firebase/firestore';
import Alert from '@mui/material/Alert';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';

export function CellarDetails(props) {
  const { t } = useTranslation();
  const {
    setOpen, setOpenWarning, open, cellar,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setOpenWarning(true);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_selection.cellar')}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <CellarDetailsContent cellar={cellar} />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleDelete}>
          {t('delete')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CellarDetailsContent(props) {
  const { t } = useTranslation();
  const { cellar } = props;
  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 1, width: '100%',
      }}
    >
      <Typography variant="body2">
        {t('cellar_selection.name')}
        :
        {cellar.id}
      </Typography>
    </Box>
  );
}

export function CellarWarningDelete(props) {
  const db = useFirestore();
  const { currentUser: user } = useAuth();
  const [globalState] = useGlobal();
  const analytics = getAnalytics();
  const { t } = useTranslation();
  const {
    setOpen, setCellars, open, cellar,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const clientCellarsPath = `${globalState.clientPath}/cellars`;
    logEvent(analytics, 'admin_delete_cellar', {
      user_uid: user?.uid,
      client_path: globalState.clientPath,
    });
    await deleteDoc(doc(db, clientCellarsPath, cellar.id));
    setCellars((prevState) => [...prevState.filter((newCellar) => newCellar.id !== cellar.id)]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('cellar_deletion.title')}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Alert severity="warning">{t('cellar_deletion.warning')}</Alert>
        <CellarDetailsContent cellar={cellar} />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleDelete}>
          {t('delete')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
