import React, { useState, useEffect, useCallback } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import OrganizationsSelect from 'components/organizations/OrganizationsSelect';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { refreshSessionAndReload } from 'components/utils';
import Refresh from '@mui/icons-material/Refresh';
import NotYetSetup from './NotYetSetup';
import TankItem from './TankItem';
import detectSavedCellarAnTank, { saveCellarAnTank } from './storeUtils';
import { getCellarIdsAndTankIds, initializeCachedTanks, isCertifInCache } from './queries';
import CellarSelect from './CellarSelect';

export default function TankSelection() {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const [cellar, setCellar] = useState('');
  const [tank, setTank] = useState('');
  const [cellarsAndTanks, setcellarsAndTanks] = useState({});
  const db = useFirestore();
  const [openError, setOpenError] = useState(false);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();
  const [loadingState, setloadingState] = useState('notLoaded');
  const [tanksOfflineStatus, setTanksOfflineStatus] = useState({});

  const checkCellarTanksOffline = useCallback(async (selectedCelllar, newCellarsAndTanks) => {
    const promises = newCellarsAndTanks[selectedCelllar]?.tanks.map(async (tankData) => {
      const isCached = await isCertifInCache(db, tankData.path);
      return { tankId: tankData.id, isCached };
    });
    const offlineStatuses = await Promise.all(promises);
    const newTanksOfflineStatus = offlineStatuses.reduce((acc, { tankId, isCached }) => {
      acc[tankId] = isCached ? 'inCache' : 'notInCache';
      return acc;
    }, {});
    setTanksOfflineStatus(newTanksOfflineStatus);
  }, [db]);

  const getSelectDefaultCellarAndTank = (newCellarsAndTanks) => {
    const savedCellarAndTank = detectSavedCellarAnTank(newCellarsAndTanks);
    const selectedCellar = savedCellarAndTank ? savedCellarAndTank.cellar : Object.keys(newCellarsAndTanks)[0];
    const selectedTank = savedCellarAndTank ? savedCellarAndTank.tank : newCellarsAndTanks[selectedCellar].tanks[0];

    return { cellar: selectedCellar, tank: selectedTank };
  };

  const fetchCellarsAndTanks = useCallback(async (forceCacheReload) => {
    setloadingState('loading');
    try {
      const newCellarsAndTanks = await getCellarIdsAndTankIds(
        db,
        globalState.clientPath,
        globalState,
        forceCacheReload,
      );
      setcellarsAndTanks(newCellarsAndTanks);
      const defaultSelects = getSelectDefaultCellarAndTank(newCellarsAndTanks);
      setCellar(defaultSelects.cellar);
      setTank(defaultSelects.tank || '');
      checkCellarTanksOffline(defaultSelects.cellar, newCellarsAndTanks);
      initializeCachedTanks(newCellarsAndTanks, setTanksOfflineStatus);
      setloadingState('loaded');
      logEvent(analytics, 'loading_cellars_and_tanks', {
        user_uid: user?.uid,
        organization: globalState.activeOrganization,
      });
    } catch (e) {
      setloadingState('error');
      if (globalState.networkState !== 'offline') {
        console.log('Error loading cellars and tanks', e);
        logEvent(analytics, 'error_loading_cellars_and_tanks', {
          user_uid: user?.uid,
          error_message: e.message,
          organization_id: globalState.activeOrganization,
        });
        setOpenError(true);
      }
    }
  }, [analytics, checkCellarTanksOffline, db, globalState, user?.uid]);

  useEffect(() => {
    if (globalState.userStatus === 'notYetSetup') {
      setloadingState('userNotYetSetup');
    }

    if (user?.uid !== undefined && loadingState === 'notLoaded' && globalState.userStatus === 'setupDone') {
      fetchCellarsAndTanks(false);
    }
  }, [fetchCellarsAndTanks, globalState.userStatus, loadingState, user?.uid]);

  const handleChangeCellar = (event) => {
    const selectedCellar = event.target.value;
    setCellar(selectedCellar);
    setTank(cellarsAndTanks[selectedCellar].tanks[0] ?? '');
    checkCellarTanksOffline(selectedCellar, cellarsAndTanks);
  };

  const handleChangeTank = async (event) => {
    setTank(event.target.value);
  };

  const handleGoToOperation = () => {
    saveCellarAnTank(cellar, tank);
    navigate(`/operation?tankPath=${encodeURIComponent(tank.path)}`);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: 5,
    }}
    >
      <OrganizationsSelect changeCallback={() => setloadingState('notLoaded')} />
      <Paper
        elevation={0}
        sx={{
          ml: 1,
          mr: 1,
          pt: 3,
          pb: 3,
          pl: 1,
          pr: 1,
          width: '100%',
          maxWidth: 800,
        }}
      >
        {loadingState === 'loaded' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ flexDirection: 'column', m: 5 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{ mb: 2 }}
              >
                {t('tank_selection.select_cellar')}
              </Typography>
              <CellarSelect
                cellar={cellar}
                handleChangeCellar={handleChangeCellar}
                tanksOfflineStatus={tanksOfflineStatus}
                cellarsAndTanks={cellarsAndTanks}
                checkCellarTanksOffline={checkCellarTanksOffline}
              />

            </Box>
            <Box sx={{ flexDirection: 'column', m: 5 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{ mb: 2 }}
              >
                {t('tank_selection.select_tank')}
              </Typography>
              <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                <InputLabel id="tank-select-label">
                  {t('tank_selection.tank')}
                </InputLabel>
                <Select
                  labelId="tank-select-label"
                  id="trank-select"
                  value={tank}
                  label={t('tank_selection.tank')}
                  onChange={handleChangeTank}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {cellarsAndTanks[cellar].tanks.map((u, i) => (
                    <MenuItem key={u.id} value={u}>
                      <TankItem tankListData={u} isOfflineAvailable={tanksOfflineStatus[u.id]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Button
            variant="contained"
            size="large"
            disabled={tank === ''}
            sx={{ mt: 5 }}
            onClick={handleGoToOperation}
            endIcon={<ArrowForward />}
          >
            {t('tank_selection.go_to_operation')}
          </Button>
          <Button
            sx={{ mt: 2 }}
            variant="text"
            color="primary"
            onClick={() => fetchCellarsAndTanks(true)}
            endIcon={<Refresh />}
          >
            {t('reload')}
          </Button>
        </Box>
        )}
        {(loadingState === 'notLoaded'
      || loadingState === 'loading') && <CircularProgress />}
        {loadingState === 'userNotYetSetup' && (
        <NotYetSetup />
        )}
        {loadingState === 'error' && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => refreshSessionAndReload()}
          endIcon={<Refresh />}
        >
          {t('reload')}
        </Button>
        )}
        <Snackbar
          open={openError}
          onClose={() => setOpenError(false)}
          style={{ marginTop: 200 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert style={{ padding: 50 }} severity="error">
            <AlertTitle>{t('tank_selection.cant_load_title')}</AlertTitle>
            {t('tank_selection.cant_load')}
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              color="primary"
              onClick={() => refreshSessionAndReload()}
              endIcon={<Refresh />}
            >
              {t('reload')}
            </Button>
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
}
