import {
  doc, setDoc, Timestamp,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';

export default async function saveOperationToFirestore(
  db,
  clientPath,
  analytics,
  user,
  tankNumber,
  certificatePath,
  cellar,
  tankName,
  cellarName,
  certificateName,
  form,
  volumeConversionResults,
  volumeConversionMethod = 'OIML-OIV',
) {
  const operationId = `${tankNumber}-${Timestamp.now().toMillis()}`;
  const userUid = user.uid;
  const userEmail = user.email;
  const operation = {
    tankNumber,
    certificatePath,
    certificateNumber: certificatePath.toString().split('/')[7],
    cellar,
    tankName: tankName || tankNumber,
    cellarName: cellarName || cellar,
    certificateName: certificateName || certificatePath.toString().split('/')[7],
    form,
    dateAndTime: Timestamp.now(),
    id: operationId,
    userUid,
    userEmail,
  };
  if (volumeConversionResults) {
    operation.volumeConversionResults = volumeConversionResults;
    operation.volumeConversionMethod = volumeConversionMethod;
  }
  const operationRef = doc(db, clientPath, 'operations', operationId);
  await setDoc(operationRef, operation);

  logEvent(analytics, 'save_operation', {
    user_uid: user?.uid,
    client_path: clientPath,
    tank_id: tankNumber,
  });
}
