import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function TankDetail(props) {
  const {
    open, setOpen, tank, tankPath, tankDeleteCallback,
  } = props;
  const { t } = useTranslation();
  const [openWarning, setopenWarning] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setopenWarning(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_selection.tank')}
      </DialogTitle>
      <DialogContent>
        <TankContent tank={tank} tankPath={tankPath} setopenWarning={setopenWarning} handleClose={handleClose} />
      </DialogContent>
      <TankWarningDelete
        tankDeleteCallback={tankDeleteCallback}
        tankPath={tankPath}
        tank={tank}
        openWarning={openWarning}
        handleClose={handleClose}
        setopenWarning={setopenWarning}
      />
    </Dialog>
  );
}

function TankContent(props) {
  const { t } = useTranslation();
  const {
    tank, tankPath, setopenWarning, handleClose,
  } = props;
  const navigate = useNavigate();
  const db = useFirestore();
  const tankRef = doc(db, tankPath);
  const [certificateExpirationDate, setCertificateExpirationDate] = useState('');
  const [isNewType, setisNewType] = useState(false);
  const [pdfPath, setPdfPath] = useState('');
  const [certificateData, setCertificateData] = useState(undefined);

  const isNewCertificateModel = (certifData) => certifData.reference;

  const getCertifData = useCallback(async (certifData) => {
    if (isNewCertificateModel(certifData)) {
      setisNewType(true);
      const certifRef = doc(db, certifData.reference.path);
      const certifDoc = await getDoc(certifRef);
      setCertificateData(certifDoc.data());
      setPdfPath(`gs://labox-ws.appspot.com/${certifData.reference.path}.pdf`);
      return {
        validityDate: new Date(certifDoc.data().expirationDate.toMillis()).toLocaleDateString(),
      };
    }
    setCertificateData(certifData);
    setPdfPath(`gs://labox-ws.appspot.com/${tankPath}/gaugeCertificates/${tank.activeCertificate}.pdf`);
    return {
      validityDate: new Date(certifData.expirationDate).toLocaleDateString(),
    };
  }, [db, tank.activeCertificate, tankPath]);

  useEffect(() => {
    async function getCertificate() {
      const tankDoc = await getDoc(tankRef);
      const tankData = tankDoc.data();
      const certifRef = doc(db, tankDoc.ref.path, 'gaugeCertificates', tankData.activeCertificate);
      const certifDoc = await getDoc(certifRef);
      const certifData = await getCertifData(certifDoc.data());
      setCertificateExpirationDate(certifData.validityDate);
    }

    if (certificateExpirationDate === '') {
      getCertificate();
    }
  }, [certificateExpirationDate, db, getCertifData, tankRef]);

  const handleUpdate = () => {
    navigate('create', {
      state: {
        update: true,
        tankId: tank.id,
        cellar: tank.cellar,
        heightUnit: 'm',
        volumeUnit: 'm3',
        capacity: tank.capacity,
      },
    });
  };

  const handleGoToPDFViewer = () => {
    // eslint-disable-next-line max-len
    navigate(`/pdfViewer?filePath=${encodeURIComponent(pdfPath)}`);
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%',
      }}
    >
      <Typography variant="body2">
        {t('tank_selection.cellar')}
        :
        {' '}
        {tank.cellarName}
        <br />
        {t('tank_selection.tank')}
        :
        {' '}
        {tank.name}
        <br />
        {t('tank_selection.certificate')}
        :
        {' '}
        {certificateData?.certificateName || certificateData?.id || ''}
        <br />
        {t('tank_selection.valid_until')}
        :
        {' '}
        {certificateExpirationDate}
      </Typography>
      {certificateData && certificateData.version && (
      <Typography variant="body2" sx={{ mt: -3 }}>
        {t('version')}
        :
        {certificateData.version}
      </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {pdfPath !== '' && (
          <Button
            onClick={handleGoToPDFViewer}
            size="small"
            variant="contained"
            color="primary"
          >
            {t('storage.certificate_pdf')}
          </Button>
        )}
        {pdfPath === '' && (
          <CircularProgress size={25} />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        <Button color="error" onClick={() => setopenWarning(true)} disabled={isNewType}>
          {t('delete')}
        </Button>
        <Button variant="outlined" onClick={handleUpdate} disabled={isNewType}>
          {t('admin.new_gauging')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </Box>
    </Box>
  );
}

function TankWarningDelete(props) {
  const db = useFirestore();
  const { t } = useTranslation();
  const {
    handleClose, tankDeleteCallback, openWarning, tankPath, setopenWarning,
  } = props;

  const handleDelete = async () => {
    await deleteDoc(doc(db, tankPath));
    tankDeleteCallback();
    handleClose();
  };

  const handleThisClose = () => {
    setopenWarning(false);
  };

  return (
    <Dialog
      open={openWarning}
      onClose={handleThisClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Typography variant="body2">{t('admin.tank_warning_deletion')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleDelete}>
          {t('delete')}
        </Button>
        <Button onClick={handleThisClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
