import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';

export default function TankItem({ tankListData, isOfflineAvailable }) {
  const isInCacheIcon = () => {
    switch (isOfflineAvailable) {
      case 'inCache':
        return <SecurityUpdateGoodIcon sx={{ fontSize: 18, color: 'primary.main' }} />;
      case 'notInCache':
        return null;
      case 'initial':
      default:
        return <CircularProgress size={18} />;
    }
  };

  return (
    <Box sx={{
      display: 'flex', gap: 1, justifyContent: 'space-between', alignItems: 'center', width: '100%',
    }}
    >
      {tankListData.name}
      {isInCacheIcon()}
    </Box>
  );
}
