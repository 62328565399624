import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Done from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import FirestoreSaveConfirm from './FirestoreSaveConfirm';

export default function OperationButtons(props) {
  const { t } = useTranslation();
  const [saveOpen, setSaveOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [globalState] = useGlobal();

  const {
    certificatePath, tankNumber, cellar, form, tankName, cellarName, certificateName,
  } = props;

  const handleClickSaveOpen = () => {
    if (validate()) {
      setSaveOpen(true);
    }
  };

  function alertWithError(message) {
    setErrorMessage(message);
    setOpenError(true);
  }

  function hasErrors() {
    return Object.values(form.fields).some(
      (f) => f.error !== null,
    ) || form.volumeToAdd.error !== null;
  }

  function validate() {
    if (hasErrors()) {
      alertWithError(t('operation.has_errors'));
      return false;
    }
    if (form.results.volume <= 0 || form.results.volume === '-') {
      alertWithError(t('operation.total_volume_zero'));
      return false;
    }
    return true;
  }

  function validateNetwork() {
    if (globalState.networkState === 'offline') {
      alertWithError(t('forms.need_network'));
      return false;
    }
    return true;
  }

  const handleGoToVolumeConversion = () => {
    const hookUrl = new URL(window.location);
    hookUrl.searchParams.append('form', JSON.stringify({ ...form }));
    const volumeDeeplink = new URL('https://alcoholometry.labox-apps.com/deeplink');
    volumeDeeplink.searchParams.append('type', 'volumeConversion');
    volumeDeeplink.searchParams.append('hookName', 'DigiTank GC');
    volumeDeeplink.searchParams.append('hookUrl', hookUrl);
    volumeDeeplink.searchParams.append('volumeUnit', 'hL');
    volumeDeeplink.searchParams.append('volume', form.results.volume);
    volumeDeeplink.searchParams.append('volumeUnit', 'hL');

    if (validate() && validateNetwork()) {
      window.location.assign(volumeDeeplink.href);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 3,
      }}
    >
      <Button
        variant="contained"
        size="large"
        onClick={handleClickSaveOpen}
        endIcon={<Done />}
      >
        {t('operation.done')}
      </Button>

      <Button
        variant="text"
        size="small"
        onClick={handleGoToVolumeConversion}
        endIcon={<ExitToAppIcon />}
      >
        {t('to_alcoholometry')}
      </Button>

      <FirestoreSaveConfirm
        saveOpen={saveOpen}
        successOpen={successOpen}
        setSaveOpen={setSaveOpen}
        setSuccessOpen={setSuccessOpen}
        form={form}
        certificatePath={certificatePath}
        tankNumber={tankNumber}
        cellar={cellar}
        tankName={tankName}
        cellarName={cellarName}
        certificateName={certificateName}
      />
      <Snackbar
        open={openError}
        style={{ marginTop: 200 }}
        autoHideDuration={15000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert style={{ padding: 50 }} severity="warning">
          <AlertTitle>{t('operation.cant_save')}</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
