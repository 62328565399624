import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import {
  TextField, Dialog, Button, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import EmailIcon from '@mui/icons-material/Email';
import { useFunctions } from 'firebaseHooks/FunctionsContext';

function ContactGauger() {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const contactGauger = httpsCallable(functions, 'contactGaugerCall');

  const sendMessage = async () => {
    if (!message) {
      globalActions.setSnackbarMessage({ message: 'Entrer un message', severity: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      await contactGauger({
        message,
        gaugerOrganizationId: globalState.gaugerCollaborations[0],
        fromOrganizationId: globalState.activeOrganization,
      });
      setMessage('');
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err.message}`, severity: 'error' });
    }
    globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
    setIsLoading(false);
    setOpen(false);
  };

  // We make sure that there is only one gauger now. There must be at least one.
  // If there are more we need to improve implementation.
  // until now this was sufficient
  if (globalState.gaugerCollaborations.length !== 1) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        endIcon={<EmailIcon />}
        sx={{ textAlign: 'left' }}
      >
        {t('contact_gauger.title')}
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="contact_gauger_label"
        aria-describedby="contact-auger-description"
        sx={{ p: 3 }}
      >
        <DialogTitle>
          Message
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            sx={{ width: 600 }}
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder={t('contact_gauger.placeholder')}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={sendMessage}
            disabled={!message || isLoading}
          >
            {t('send')}
          </LoadingButton>
        </DialogActions>

      </Dialog>
    </>

  );
}

export default ContactGauger;
