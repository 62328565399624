import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewCertificatesAvailableDetail from './NewCertificatesAvailableDetail';

function NewCertificateAvailableItem({ notification }) {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, p: 1,
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)} : ${notification.certificateId}`}
      </Typography>
    </Box>
  );
}

function NewCertificatesAvailableItem({ notification }) {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', border: 1, borderRadius: 1, p: 1, width: '100%',
    }}
    >
      <Typography>
        {`${t(`notifications.${notification.notificationType}Text`)}`}
      </Typography>
      <Typography variant="body2" sx={{ alignSelf: 'flex-end' }}>
        {new Date(notification.dateAndTime.toMillis()).toLocaleString()}
      </Typography>
    </Box>
  );
}

export function notificationItemFactory(notification) {
  switch (notification.notificationType) {
    case 'newCertificateAvailable':
      return <NewCertificateAvailableItem notification={notification} />;
    case 'newCertificatesAvailable':
      return <NewCertificatesAvailableItem notification={notification} />;
    default:
      return (
        <Typography sx={{ border: 1, borderRadius: 1, p: 1 }}>
          {`Unknown type : ${notification.notificationType}`}
        </Typography>
      );
  }
}

export function notificationDetailFactory(notification, handleCloseDetail) {
  switch (notification.notificationType) {
    case 'newCertificatesAvailable':
      return <NewCertificatesAvailableDetail notification={notification} handleCloseDetail={handleCloseDetail} />;
    default:
      return <Typography sx={{ p: 3 }}>{`Unknown type : ${notification.notificationType}`}</Typography>;
  }
}
