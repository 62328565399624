import * as React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import { useLocation } from 'react-router-dom';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import {
  getCellarsOfClient,
} from './firestoreRequests';
import TankCreationForm from './TankCreationForm';

export default function TankCreation() {
  const db = useFirestore();
  const analytics = getAnalytics();
  const { currentUser: user } = useAuth();
  const [loadingStatus, setloadingStatus] = React.useState('not loaded');
  const [cellars, setcellars] = React.useState([]);
  const [globalState] = useGlobal();
  const location = useLocation();
  const [update] = React.useState(location.state?.update || false);

  React.useEffect(() => {
    function callback(newCellars) {
      setcellars(newCellars);
      setloadingStatus('loaded');
      logEvent(analytics, 'loading_cellars_in_tank_creation', {
        user_uid: user?.uid,
      });
    }

    async function loadCellars() {
      setloadingStatus('loading');
      getCellarsOfClient(db, globalState.clientPath, callback);
    }

    if (user?.uid !== undefined && globalState.clientPath !== '' && loadingStatus === 'not loaded') {
      try {
        loadCellars();
      } catch (e) {
        logEvent(analytics, 'error_loading_cellars_and_tanks', {
          user_uid: user?.uid,
          error_message: e.message,
        });
      }
    }
  }, [db, user?.uid, loadingStatus, analytics, globalState.clientPath]);

  if (loadingStatus === 'loaded') {
    return <TankCreationForm cellars={cellars} clientPath={globalState.clientPath} update={update} />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
