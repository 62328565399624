import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export default function PriceGraph() {
  const { t } = useTranslation();

  const subscriptionPriceData = {
    1000: 599.34,
    900: 577.61,
    800: 554.75,
    700: 530.03,
    600: 502.23,
    500: 469.37,
    300: 373.08,
    200: 295.22,
    100: 179.69,
    50: 100.67,
    40: 82.67,
    30: 63.85,
    20: 44.16,
    10: 23.56,
    3: 8.57,
    1: 4.20,
  };

  const certificateManagementPrice = 0.125;
  const certificateIntegration = 15;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: t('subscription.price_eur'),
        },
      },
      x: {
        title: {
          display: true,
          text: t('subscription.nb_of_tanks'),
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: () => {}, // Disable the title
          label(context) {
            const tankCount = context.raw.x; // Assuming 'x' is the tank count
            const subscriptionCost = context.raw.y - (tankCount * certificateManagementPrice);
            const certificateCost = tankCount * certificateManagementPrice;
            const integrationCost = tankCount * certificateIntegration;
            const totalCost = context.raw.y;
            return [
              `${t(
                'subscription.subscription_cost',
                { price: subscriptionCost.toFixed(2) },
              )}`,
              `${t(
                'subscription.certif_management_cost',
                { price: certificateCost.toFixed(2) },
              )}`,
              `${t(
                'subscription.total_subscription',
                { total: totalCost.toFixed(2) },
              )}`,
              '',
              `${t(
                'subscription.unique_payment',
                { price: certificateIntegration, total: integrationCost.toFixed(2) },
              )}`,
            ];
          },
        },
      },
    },
  };

  const data = {
    labels: Object.keys(subscriptionPriceData).map(Number),
    datasets: [{
      label: t('subscription.price_eur'),
      data: Object.entries(subscriptionPriceData).map(([tanks, price]) => ({
        x: Number(tanks),
        y: price + Number(tanks) * certificateManagementPrice,
      })),
      borderColor: '#437b89',
      backgroundColor: '#437b89',
      showLine: true,
    }],
  };

  return (
    <Scatter options={options} data={data} />
  );
}
