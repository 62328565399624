import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button, IconButton, Paper, TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import * as Yup from 'yup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useFunctions } from 'firebaseHooks/FunctionsContext';

export default function CreateNewOrganization({ handleComplete }) {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const analytics = getAnalytics();

  const validationSchema = Yup.object({
    name: Yup.string().required(t('required')),
    emails: Yup.array()
      .of(Yup.string().email(t('invalid_email')).required(t('required')))
      .test('unique-emails', t('duplicate_emails'), (emails) => {
        if (!emails) return true;
        const uniqueEmails = new Set(emails);
        return uniqueEmails.size === emails.length;
      }),
  });

  const handleAddEmail = () => {
    const newEmails = [...formik.values.emails, ''];
    formik.setFieldValue('emails', newEmails);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = formik.values.emails.filter((_, i) => i !== index);
    formik.setFieldValue('emails', newEmails);
  };

  const submit = async (values) => {
    try {
      const createNewOrganization = httpsCallable(functions, 'createNewOrganizationCallM2');
      const resCreateOrga = await createNewOrganization({
        name: values.name,
      });
      logEvent(analytics, 'onboarding_create_organization', {
        appName: 'Digitank, Gauged Containers',
        organization: globalState.activeOrganization,
      });
      const newOrgId = resCreateOrga.data.organizationId;
      globalActions.setActiveOrganization(newOrgId);

      const inviteOrganizationMember = httpsCallable(functions, 'inviteOrganizationMemberCallM2');

      const inviteErrors = [];
      await Promise.all(values.emails.map(async (mail) => {
        try {
          await inviteOrganizationMember({ organizationId: newOrgId, email: mail });
        } catch (inviteError) {
          inviteErrors.push(`${mail}: ${inviteError.message}`);
        }
      }));
      if (inviteErrors.length > 0) {
        throw new Error(`Failed to send invitations to: ${inviteErrors.join(', ')}`);
      }
      globalActions.setSnackbarMessage({ message: t('onboarding.org_created'), severity: 'success' });
      handleComplete();
    } catch (error) {
      if (error.message.startsWith('Failed to send invitations')) {
        globalActions.setSnackbarMessage({
          message: t('onboarding.org_created_members_failed'),
          severity: 'success',
        });
        handleComplete();
      } else {
        globalActions.setSnackbarMessage({
          message: `${t('unexpected_error')} : ${error.message}`,
          severity: 'error',
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      emails: [],
    },
    validationSchema,
    onSubmit(values) {
      return submit(values);
    },
  });

  return (
    <Paper
      elevation={0}
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        width: '100%',
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h6">
        {t('onboarding.create_org')}
      </Typography>
      <TextField
        sx={{ width: 250 }}
        autoFocus
        margin="dense"
        id="name"
        name="name"
        label={t('name')}
        type="text"
        fullWidth
        value={formik.values.name}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.name)}
        helperText={formik.errors.name}
      />
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {t('onboarding.invite_members')}
      </Typography>
      {formik.values.emails.map((email, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`email-field-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TextField
            size="small"
            id={`emails.[${index}]`}
            name={`emails.[${index}]`}
            value={email}
            label={`${t('email')} ${index + 1}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.emails?.[index]
              && Boolean(formik.errors.emails?.[index])}
            helperText={formik.touched.emails?.[index]
              && formik.errors.emails?.[index]}
          />
          <IconButton onClick={() => handleRemoveEmail(index)} size="small">
            <RemoveIcon />
          </IconButton>
        </Box>
      ))}

      <Button onClick={handleAddEmail} size="medium" variant="outlined" endIcon={<AddIcon />}>
        {t('admin.add_email')}
      </Button>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        type="submit"
        color="primary"
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {t('create')}
      </Button>
    </Paper>
  );
}
