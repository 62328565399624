import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NumberInput } from 'components/Inputs';

export default function CompartmentField(props) {
  const {
    updateForm, name, inputStyle, label, inputMode, decimalScale, value, highlighted, error,
  } = props;
  const handleBlur = (e) => {
    updateForm(e.target.value, name);
  };

  const handleKeyPressed = (e) => {
    const key = e.key ? e.key : e.code;
    if (['Enter', 'NumpadEnter'].includes(key)) {
      handleBlur(e);
    }
  };

  const handleChange = (e) => {
    // To update on each character change
  };

  return (
    <Box sx={{ ...inputStyle }}>
      <NumberInput
        label={label}
        id={name}
        inputMode={inputMode}
        name={name}
        decimalScale={decimalScale}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPressed}
        highlighted={highlighted}
      />
      <Typography
        variant="caption"
        sx={{ color: '#e21f1f' }}
        display="block"
        gutterBottom
      >
        {error}
      </Typography>
    </Box>
  );
}
