import React, { useCallback, useEffect } from 'react';
import useGlobal from 'global-state/store';
import { doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import GaugerCard from './GaugerCard';
import ConnectWithGauger from './ConnectWithGauger';

export default function GaugerCollaboration() {
  const [globalState] = useGlobal();
  const [pageState, setPageState] = useGlobal('initial');
  const firestore = useFirestore();

  const loadConfig = useCallback(async () => {
    if (globalState.gaugerCollaborations.length > 0) {
      setPageState('gaugerAvailable');
      return;
    }
    const appRef = doc(
      firestore,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'digitank-gauged-containers',
    );
    const appDoc = await getDoc(appRef);
    const { billingMethod } = appDoc.data();
    if (billingMethod === 'manual') {
      setPageState('specialCaseMixedSetup');
      return;
    }
    setPageState('gaugerNotAvailable');
  }, [firestore, globalState.activeOrganization, globalState.gaugerCollaborations.length, setPageState]);

  useEffect(() => {
    if (pageState === 'initial') {
      loadConfig();
    }
  }, [loadConfig, pageState]);

  if (pageState === 'specialCaseMixedSetup') {
    return null;
  }
  if (pageState === 'gaugerNotAvailable') {
    return <ConnectWithGauger />;
  }
  return (
    <GaugerCard />
  );
}
