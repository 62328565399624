export default function detectSavedCellarAnTank(newCellarsAndTanks) {
  let savedCellarAndTank = getSavedCellarAnTank();
  if (savedCellarAndTank) {
    const savedCellar = savedCellarAndTank.cellar;
    const savedTank = savedCellarAndTank.tank;

    const foundSavedTank = newCellarsAndTanks[savedCellar]?.tanks.find(
      (at) => at.id === savedTank,
    );
    if (foundSavedTank) {
      savedCellarAndTank = {
        cellar: savedCellar,
        tank: foundSavedTank,
      };
    } else {
      localStorage.removeItem('saved-cellar-and-tank');
      savedCellarAndTank = undefined;
    }
  }
  return savedCellarAndTank;
}

function getSavedCellarAnTank() {
  let savedCellarAndTank = localStorage.getItem(
    'saved-cellar-and-tank',
  );
  if (savedCellarAndTank) {
    savedCellarAndTank = JSON.parse(savedCellarAndTank);
  }
  return savedCellarAndTank;
}

export function saveCellarAnTank(cellar, tank) {
  localStorage.setItem(
    'saved-cellar-and-tank',
    JSON.stringify({
      cellar,
      tank: tank.id,
    }),
  );
}
