import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  collection,
  query,
  getDocs,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

export default function CellarSelect({ selectCallback, isCellarValid, defaultCellar = false }) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const [cellars, setCellars] = useState([]);
  const [pageStatus, setPageStatus] = useState('initial');
  const [currentCellar, setCurrentCellar] = useState('');
  const firestore = useFirestore();

  const handleChange = useCallback((newCellar) => {
    setCurrentCellar(newCellar);
    selectCallback(newCellar.id, cellars.includes(newCellar));
  }, [cellars, selectCallback]);

  const getCellars = useCallback(async () => {
    const cellarsQuery = query(collection(
      firestore,
      `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/cellars`,
    ));
    const querySnapshot = await getDocs(cellarsQuery);
    const cellarDocs = querySnapshot.docs;

    const newCellars = cellarDocs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));

    setCellars(newCellars);
    if (defaultCellar) {
      handleChange(newCellars[0] || { id: '', name: '' });
    }

    setPageStatus('loaded');
  }, [defaultCellar, firestore, globalState.activeOrganization, handleChange]);

  useEffect(() => {
    if (pageStatus === 'initial' && globalState.activeOrganization !== '') {
      setPageStatus('loading');
      getCellars();
    }
  }, [getCellars, globalState.activeOrganization, pageStatus]);

  const handleAutocompleteChange = (event, value) => {
    handleChange(value);
  };

  const handleInputChange = (event, value) => {
    if (value === '') {
      handleChange({ id: '', name: '' });
    }
  };

  return (
    <>
      {pageStatus === 'loaded' && (
      <Autocomplete
        freeSolo
        size="small"
        sx={{ flexGrow: 1 }}
        options={cellars}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={cellars.find((cellar) => cellar.name === currentCellar) || null}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            fullWidth={params.fullWidth}
            id={params.id}
            disabled={params.disabled}
            size={params.size}
            label={t('tank_selection.cellar')}
            inputProps={{ maxLength: 30 }}
            error={!isCellarValid}
            helperText={!isCellarValid && t('required')}
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
          />
        )}
      />
      )}
      {pageStatus !== 'loaded' && <CircularProgress />}
    </>
  );
}
