import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useGlobal from 'global-state/store';
import { CircularProgress, Paper, TextField } from '@mui/material';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  collection, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';

function ExtraOperationFields({ setExtraFields, tankId }) {
  const [globalState] = useGlobal();
  const stateExtraFields = globalState.extraOperationFields;

  if (stateExtraFields.length === 0) {
    return null;
  }

  return (
    <ExtraOperationFieldsContent stateExtraFields={stateExtraFields} setExtraFields={setExtraFields} tankId={tankId} />
  );
}

function ExtraOperationFieldsContent({ stateExtraFields, tankId, setExtraFields }) {
  const firestore = useFirestore();
  const [globalState] = useGlobal();
  const [data, setData] = useState(null);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    const fetchLastOperation = async () => {
      const operationsRef = collection(
        firestore,
        `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/operations`,
      );
      const queryDocs = query(
        operationsRef,
        where('tankNumber', '==', tankId),
        orderBy('dateAndTime', 'desc'),
        limit(1),
      );
      const snapshot = await getDocs(queryDocs);
      if (!snapshot.empty) {
        const operation = snapshot.docs[0].data();
        setData(operation);
        if (operation.form?.extraFields) {
          setExtraFields(snapshot.docs[0].data().form.extraFields);
        }
      }
      setStatus('loaded');
    };

    if (globalState.activeOrganization && status === 'initial') {
      setStatus('loading');
      fetchLastOperation();
    }
  }, [firestore, globalState.activeOrganization, setExtraFields, status, tankId]);

  if (status !== 'loaded') {
    return <CircularProgress sx={{ alignSelf: 'center', my: 3 }} />;
  }

  return (
    <ExtraOperationFieldsForm
      stateExtraFields={stateExtraFields}
      storedExtraFields={data?.form?.extraFields || []}
      setExtraFields={setExtraFields}
    />
  );
}

function ExtraOperationFieldsForm({ stateExtraFields, storedExtraFields, setExtraFields }) {
  const initialValues = stateExtraFields.reduce((values, field) => {
    const storedField = storedExtraFields.find((f) => f.name === field.name);
    // eslint-disable-next-line no-param-reassign
    values[field.name] = storedField?.value || '';
    return values;
  }, {});

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const updatedFields = stateExtraFields.map((field) => ({
        ...field,
        value: values[field.name],
      }));
      setExtraFields(updatedFields);
    },
  });

  return (
    <Paper
      elevation={0}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        mt: 2,
      }}
    >
      {stateExtraFields.map((field) => (
        <TextField
          key={field.name}
          id={field.name}
          name={field.name}
          label={field.display}
          type={field.type}
          value={formik.values[field.name]}
          onBlur={(e) => {
            formik.handleSubmit();
          }}
          onChange={formik.handleChange}
        />
      ))}
    </Paper>
  );
}

export default ExtraOperationFields;
