const { unit } = require('mathjs');
const { createUnit } = require('mathjs');
const { Unit } = require('mathjs');
const { round } = require('components/utils');

if (!Unit.isValuelessUnit('qtUS')) {
  createUnit({
    barrelUS: '119.2405 L',
    barrelUK: '163.6592 L',
    flozUK: '0.0284131 L',
    flozUS: '0.0295735 L',
    galUK: '4.54609 L',
    galUS: '1.13652 L',
    giUK: '0.1420653125 L',
    giUS: '0.11829411825 L',
    pintUK: '0.568261 L',
    pintUS: '0.473176 L',
    qtUK: '1.13652 L',
    qtUS: '0.946353 L',
  });
}

if (!Unit.isValuelessUnit('mgP100mL')) {
  createUnit({
    mgP100mL: '1 g/hL',
  });
}

if (!Unit.isValuelessUnit('AbVol')) {
  createUnit('AbVol');
  createUnit({
    proofUS: '0.5 AbVol',
    proofUK: '0.57 AbVol',
    AbWt: '1.26 AbVol',
  });
}

const unitFormatPrecision = 10;

const unitMasks = {
  m3: 3,
  hL: 2,
  L: 0,
  dL: 0,
  cL: 0,
  mL: 0,
  barrelUS: 3,
  barrelUK: 3,
  flozUK: 2,
  flozUS: 2,
  galUK: 3,
  galUS: 3,
  giUK: 3,
  giUS: 3,
  pintUK: 3,
  pintUS: 3,
  qtUK: 3,
  qtUS: 3,
  m: 3,
  cm: 1,
  mm: 0,
};

const baseUnitRelatedRestrictionsDict = {
  tav_min_AbVol: 0,
  tav_max_AbVol: 100,
  tav_mutage_min_AbVol: 0,
  tav_mutage_max_AbVol: 100,
  temp_min_celsius: 0,
  temp_max_celsius: 35,
  volume_min_L: 0,
  volume_max_L: 99999999,
  diameter_min_cm: 0,
  diameter_max_cm: 99999999,
};

const unitNamesDict = {
  proofUS: 'proof (US)',
  proofUK: 'proof (UK)',
  AbVol: '% vol.',
  TAV: '% vol.',
  celsius: '°C',
  fahrenheit: '°F',
  mgP100mL: 'mg/100mL',
  m3: 'm³',
  hL: 'HL',
  barrelUS: 'barrel (US)',
  barrelUK: 'barrel (UK)',
  galUS: 'gal (US)',
  galUK: 'gal (UK)',
  L: 'L (dm³)',
  dL: 'dl',
  cL: 'cl',
  mL: 'ml (cm³)',
  flozUK: 'floz (UK)',
  flozUS: 'floz (US)',
  giUK: 'gi (UK)',
  giUS: 'gi (US)',
  pintUK: 'pint (UK)',
  pintUS: 'pint (US)',
  qtUK: 'qt (UK)',
  qtUS: 'qt (US)',
};

function baseUnitDictTo(baseName, baseUnit, newUnit) {
  return round(
    unit(baseUnitRelatedRestrictionsDict[baseName], baseUnit)
      .to(newUnit)
      .toNumber(),
    1,
  );
}

const additionalUnitRelatedRestrictionsDict = {
  tav_min_proofUS: baseUnitDictTo('tav_min_AbVol', 'AbVol', 'proofUS'),
  tav_max_proofUS: baseUnitDictTo('tav_max_AbVol', 'AbVol', 'proofUS'),
  tav_min_proofUK: baseUnitDictTo('tav_min_AbVol', 'AbVol', 'proofUK'),
  tav_max_proofUK: baseUnitDictTo('tav_max_AbVol', 'AbVol', 'proofUK'),
  tav_mutage_min_proofUS: baseUnitDictTo(
    'tav_mutage_min_AbVol',
    'AbVol',
    'proofUS',
  ),
  tav_mutage_max_proofUS: baseUnitDictTo(
    'tav_mutage_max_AbVol',
    'AbVol',
    'proofUS',
  ),
  tav_mutage_min_proofUK: baseUnitDictTo(
    'tav_mutage_min_AbVol',
    'AbVol',
    'proofUK',
  ),
  tav_mutage_max_proofUK: baseUnitDictTo(
    'tav_mutage_max_AbVol',
    'AbVol',
    'proofUK',
  ),
  temp_min_fahrenheit: baseUnitDictTo(
    'temp_min_celsius',
    'celsius',
    'fahrenheit',
  ),
  temp_max_fahrenheit: baseUnitDictTo(
    'temp_max_celsius',
    'celsius',
    'fahrenheit',
  ),
};

function convertAndRoundVolume(value, volumeUnit) {
  return convertAndRoundVolumeFromUnitToUnit(value, 'L', volumeUnit);
}

function convertAndRoundVolumeFromUnitToUnit(value, fromVolumeUnit, toVolumeUnit) {
  return convertAndRoundFromUnitToUnit(value, fromVolumeUnit, toVolumeUnit, unitMasks[toVolumeUnit]);
}

function convertAndRoundFromUnitToUnit(value, fromUnit, toUnit, precision) {
  return unit(value, fromUnit).to(toUnit).format({ precision, notation: 'fixed' });
}

function roundVolume(value, volumeUnit) {
  return unit(value, volumeUnit).format({ precision: unitMasks[volumeUnit], notation: 'fixed' });
}

function convertAndRoundTemperature(value, temperatureUnit) {
  return unit(value, 'celsius').to(temperatureUnit).format({ precision: 2, notation: 'fixed' });
}

function dynamicUnitName(unitName) {
  const changedUnit = unitNamesDict[unitName];
  if (unitNamesDict[unitName] === undefined) {
    return unitName;
  }
  return changedUnit;
}

function rewriteValueAndUnit(unitedValue) {
  const valueUnitArray = unitedValue.split(' ');
  if (valueUnitArray.length === 2) {
    const fixedUnit = dynamicUnitName(valueUnitArray[1]);
    return `${valueUnitArray[0]} ${fixedUnit}`;
  }
  return unitedValue;
}

const unitRelatedRestrictionsDict = {

  ...baseUnitRelatedRestrictionsDict,
  ...additionalUnitRelatedRestrictionsDict,
};

function dynamicUnitRestrictions(baseName, baseUnit, newUnit) {
  if (unitRelatedRestrictionsDict[baseName + newUnit] === undefined) {
    const newRestriction = baseUnitDictTo(
      baseName + baseUnit,
      baseUnit,
      newUnit,
    );
    unitRelatedRestrictionsDict[baseName + newUnit] = newRestriction;
  }
  return unitRelatedRestrictionsDict[baseName + newUnit];
}

export {
  convertAndRoundVolume,
  convertAndRoundVolumeFromUnitToUnit,
  convertAndRoundFromUnitToUnit,
  roundVolume,
  convertAndRoundTemperature,
  rewriteValueAndUnit,
  dynamicUnitName,
  unitMasks,
  unitFormatPrecision,
  dynamicUnitRestrictions,
};
