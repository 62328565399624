import React from 'react';
import {
  Button,
  Typography,
  TextField,
  Link as MUILink,
} from '@mui/material';
import { Formik } from 'formik';
import { sendPasswordResetEmail } from 'firebase/auth';
import Box from '@mui/material/Box';
import { logEvent } from 'firebase/analytics';

export default function PasswordForgottenCard(props) {
  const {
    setemailPasswordCardType, t, globalActions, auth, analytics,
  } = props;

  async function passwordResetEmail(email, setSubmitting) {
    try {
      await sendPasswordResetEmail(
        auth,
        email,
        { url: `${window.location.protocol}//${window.location.host}/signin?reset_success=true` },
      );
      globalActions.setSnackbarMessage({ message: t('signin.forgotten_password_mail_sent'), severity: 'info' });
      setemailPasswordCardType('signin');
    } catch (error) {
      setSubmitting(false);
      globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
      logEvent(analytics, 'reset_password_error', {
        error_message: error.message,
      });
    }
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
        gap: 1,
        width: { xs: '100%', sm: '88%' },
        maxWidth: 670,
      }}
    >
      <Typography component="span" variant="subtitle1" gutterBottom>
        {t('signin.password_forgotten')}
      </Typography>

      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = t('validations.required');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t('validations.email_invalid');
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await passwordResetEmail(values.email, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <Typography component="span">
                {t('signin.password_forgotten_info')}
              </Typography>
              <TextField
                style={{ maxWidth: 300 }}
                fullWidth
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label={t('signin.email')}
                error={touched.email && Boolean(errors.email)}
                helperText={errors.email && touched.email && errors.email}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {t('signin.reset_password')}
              </Button>
              <MUILink
                sx={{ cursor: 'pointer' }}
                onClick={() => setemailPasswordCardType('signin')}
                color="secondary"
              >
                {t('signin.return_login')}
              </MUILink>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
