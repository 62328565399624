import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Alert from '@mui/material/Alert';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';

export default function ValidationForm(props) {
  const { t } = useTranslation();
  const {
    tankNumber, certificateNumber, cellar, setLoadingState, verify,
    tankName, cellarName, certificateName,
  } = props;
  const [verification, setVerification] = React.useState(false);
  const [selectedCheck, setSelectedCheck] = React.useState('');
  const [globalState] = useGlobal();

  const validationSchema = Yup.object().shape({
    operatorName: Yup.string().required(t('validations.required')),
    comment: Yup.string(),
  });

  const db = useFirestore();
  const analytics = getAnalytics();
  const { currentUser: user } = useAuth();

  const handleSelectCheck = (checkName) => {
    if (selectedCheck === checkName) {
      setSelectedCheck('');
    } else {
      setSelectedCheck(checkName);
    }
  };

  const formik = useFormik({
    initialValues: {
      operatorName: '',
      comment: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { operatorName, comment } = values;
      handleValidationPost(operatorName, comment);
    },
  });

  const handleValidationPost = async (operatorName, comment) => {
    setLoadingState('notLoaded');
    const validationId = `${tankNumber}-${certificateNumber}-${Timestamp.now().toMillis()}`;

    const validation = {
      status: selectedCheck === 'valid' ? 'valid' : 'inValid',
      tankNumber,
      tankName: tankName || tankNumber,
      isValid: selectedCheck === 'valid',
      certificateNumber,
      certificateName: certificateName || certificateNumber,
      cellar,
      cellarName: cellarName || cellar,
      dateAndTime: Timestamp.now(),
      id: validationId,
      operator: operatorName,
      comment,
    };
    // No need to await here the result of setting the doc especially because that make it work offline
    // eslint-disable-next-line max-len
    const newValidationRef = doc(db, `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/validations/${validationId}`);
    setDoc(newValidationRef, validation);

    logEvent(analytics, 'save_validation', {
      user_uid: user?.uid,
      appName: 'Digitank, Gauged Containers',
      organization: globalState.activeOrganization,
      tank_id: tankNumber,
      certificateNumber,
      selectedCheck,
    });
    verify(validation);
    setLoadingState('loaded');
  };

  return (
    <Box>
      <Alert
        sx={{
          padding: 1, mt: 2, justifyContent: 'center',
        }}
        severity="warning"
      >
        {t('tank_selection.not_checked')}
        {': '}
        {!verification && (
        <Button
          size="small"
          variant="text"
          onClick={() => setVerification(true)}
        >
          {t('tank_selection.verify')}
        </Button>
        )}
      </Alert>

      {verification && (
      <Box
        sx={{
          pt: 1,
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="body2">{t('tank_selection.I_checked')}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Button
            variant={selectedCheck === 'valid' ? 'contained' : 'outlined'}
            onClick={() => handleSelectCheck('valid')}
          >
            {t('tank_selection.valid')}
          </Button>
          <Button
            variant={selectedCheck === 'invalid' ? 'contained' : 'outlined'}
            onClick={() => handleSelectCheck('invalid')}
          >
            {t('tank_selection.invalid')}
          </Button>
        </Box>
        {selectedCheck !== '' && (
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              alignItems: 'flex-start',
              gap: 2,
            }}
          >
            <TextField
              label={t('tank_selection.name')}
              id="operatorName"
              inputProps={{ maxLength: 30 }}
              variant="outlined"
              value={formik.values.operatorName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.operatorName && formik.touched.operatorName}
              helperText={
                formik.errors.operatorName
                && formik.touched.operatorName ? formik.errors.operatorName : ''
              }
            />
            <TextField
              label={t('tank_selection.comment')}
              id="comment"
              multiline
              maxRows={4}
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              value={formik.values.comment}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.comment && formik.touched.comment}
              helperText={
                formik.errors.comment
                && formik.touched.comment ? formik.errors.comment : ''
              }
            />
          </Box>
          <Button
            disabled={!formik.isValid || !formik.dirty}
            variant="outlined"
            type="submit"
          >
            {t('tank_selection.send_validation')}
          </Button>
        </Box>
        )}
      </Box>
      )}
    </Box>
  );
}
