import * as yup from 'yup';

export default function validationSchema(t, cellars, acceptedHeightUnit, acceptedVolumeUnit) {
  // https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
  const validFirestoreIdExp = /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/;
  const firestoreIdExpErrorMessage = `${t('tank_creation.invalid_utf8')}\n${
    t('tank_creation.less_than_1500')}\n${
    t('tank_creation.no_slash')}\n${
    t('tank_creation.not_a_dot')}\n${
    t('tank_creation.not__.*__')}`;

  function validateCellar(newCellar) {
    if (!cellars.includes(newCellar)) {
      return false;
    }
    return true;
  }

  return yup.object().shape({
    cellar: yup.string()
      .test(
        'cellar',
        t('tank_creation.cellar_no_exist'),
        (newCellar) => validateCellar(newCellar),
      )
      .required(t('validations.required')),
    certificateNumber: yup.string()
      .matches(validFirestoreIdExp, firestoreIdExpErrorMessage)
      .required(t('validations.required')),
    tankId: yup.string()
      .matches(validFirestoreIdExp, firestoreIdExpErrorMessage)
      .required(t('validations.required')),
    provider: yup.string().required(t('validations.required')),
    heightUnit: yup.string()
      .oneOf(acceptedHeightUnit, t('tank_creation.invalid_height_unit'))
      .required(t('tank_creation.invalid_height_unit')),
    volumeUnit: yup.string()
      .oneOf(acceptedVolumeUnit, t('tank_creation.invalid_volume_unit'))
      .required(t('tank_creation.invalid_volume_unit')),
    capacity: yup
      .number()
      .required(t('validations.required'))
      .min(0, t('validations.more_than_0')),
    nominalVolume: yup
      .number()
      .required(t('validations.required'))
      .min(0, t('validations.more_than_0')),
    expirationDate: yup.date().required(t('validations.required')),
    gaugeDate: yup.date().required(t('validations.required')),
    compartmentsData: yup.string().required(),
  });
}
