import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Paper from '@mui/material/Paper';
import {
  where,
} from 'firebase/firestore';
import { useAuth } from 'firebaseHooks/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import Filters from 'components/admin/managingTanks/TankFilters';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OurPagination from 'components/OurPagination';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

export default function Validations() {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();

  const computeListOfConditions = useCallback((formikValues) => {
    let conditions = [
      where('dateAndTime', '<=', formikValues.endDate),
      where('dateAndTime', '>=', formikValues.startDate),
    ];
    if (formikValues.cellar !== '') {
      conditions = [...conditions, where('cellar', '==', formikValues.cellar)];
    }
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    cellar: '',
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 2);
    return d;
  }

  function initialEndDate() {
    const d = new Date();
    d.setHours(23, 59, 0, 0); // next before midnight
    return d;
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_validations', {
        user_uid: user?.uid,
        appName: 'Digitank, Gauged Containers',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 2,
    }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.validations')}
      </Typography>
      {globalState.activeOrganization !== '' && (
        <OurPagination
          collectionPath={`organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/validations`}
          listOfConditions={listOfConditions}
          orderByAttribute="dateAndTime"
          filters={(
            <ValidationListFilters
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
            />
          )}
          list={(
            <ValidationList />
          )}
        />
      )}
      {globalState.activeOrganization === '' && (
        <CircularProgress />
      )}
    </Box>
  );
}

function ValidationListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
}) {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    cellar: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: formikInitValues,
    validationSchema,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <Filters formik={formik} datePickersLabel={t('admin.made')} />
    </Box>
  );
}

function ValidationList({ docs }) {
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedValidation, setSelectedValidation] = useState(null);
  const { t } = useTranslation();

  const handleClickItemOpen = (v) => {
    setSelectedValidation(v);
    setOpenDetails(true);
  };

  const handleItemClose = () => {
    setOpenDetails(false);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <ListHeader />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {docs.map((op, i) => (
            <div key={op.id}>
              <ListValidationItem
                validation={op.data()}
                index={i}
                onClick={() => handleClickItemOpen(op.data())}
              />
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
      <Dialog open={openDetails} onClose={handleItemClose}>
        <DialogTitle>{t('tank_selection.comment')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedValidation?.comment}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleItemClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function ListValidationItem(props) {
  const { index, validation, onClick } = props;

  return (
    <ListItem key={index} component="div">
      <ListItemButton sx={{ py: 1 }} onClick={onClick}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {validation.cellarName || validation.cellar}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {validation.tankName || validation.tankNumber}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {validation.certificateName || validation.certificateNumber}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {validation.operator}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {new Date(validation.dateAndTime.toMillis()).toLocaleString()}
          </Typography>
          <Typography sx={{ flexGrow: 1, flexBasis: 1, textAlign: 'center' }}>
            {validation.isValid ? <CheckIcon /> : <ErrorIcon />}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

function ListHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography>{t('admin.cellar')}</Typography>
      <Typography>{t('admin.tank_id')}</Typography>
      <Typography>{t('admin.certificate')}</Typography>
      <Typography>{t('admin.operator')}</Typography>
      <Typography sx={{ width: 155 }}>{t('admin.date')}</Typography>
      <Typography>{t('admin.isValid')}</Typography>
    </Box>
  );
}
