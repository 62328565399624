import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import Alert from '@mui/material/Alert';
import useGlobal from 'global-state/store';

export default function CellarCreation(props) {
  const db = useFirestore();
  const [globalState] = useGlobal();
  const emptyCellar = {
    id: '',
  };
  const [cellar, setCellar] = React.useState(emptyCellar);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();
  const {
    setOpen, cellars, setCellars, open,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    if (!/^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(cellar.id)) {
      setError(`${t('tank_creation.invalid_utf8')}\n${
        t('tank_creation.less_than_1500')}\n${
        t('tank_creation.no_slash')}\n${
        t('tank_creation.not_a_dot')}\n${
        t('tank_creation.not__.*__')}`);
    } else if (cellars.some((existingCellar) => existingCellar.id === cellar.id)) {
      setError(t('cellar_creation.already_exists'));
    } else {
      setError(t(''));
      await setDoc(doc(db, `${globalState.clientPath}/cellars`, cellar.id), cellar);
      setCellars((prevState) => [...prevState, cellar]);
      setCellar(emptyCellar);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('cellar_creation.add_cellar')}
      </DialogTitle>
      <DialogContent>
        <CellarCreationContent
          cellar={cellar}
          setCellar={setCellar}
        />
      </DialogContent>
      {error.length > 0 && <Alert severity="error">{error}</Alert>}
      <DialogActions>
        <Button color="primary" onClick={handleCreate}>
          {t('cellar_creation.add')}
        </Button>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CellarCreationContent(props) {
  const { cellar, setCellar } = props;
  const { t } = useTranslation();

  const handleIdChange = (event) => {
    setCellar({ id: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%', mt: 2,
      }}
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={cellar.id}
        onChange={handleIdChange}
        label={t('cellar_creation.cellar_id')}
      />

    </Box>
  );
}
