import {
  query, collection, getDocs, doc, getDoc,
} from 'firebase/firestore';

export async function getCellarsOfClient(db, clientPath, callback) {
  const cellarsQuery = query(collection(db, clientPath, 'cellars'));
  const querySnapshot = await getDocs(cellarsQuery);
  const cellarDocs = querySnapshot.docs;
  const newCellars = cellarDocs.map((cellarDoc) => cellarDoc.id);
  callback(newCellars);
}

export async function tankAlreadyExists(db, clientPath, cellarName, tankId) {
  const docRef = doc(db, clientPath, 'cellars', cellarName, 'tanks', tankId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
}
