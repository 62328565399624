import React, { useState, useEffect } from 'react';
import {
  Box, Collapse, IconButton, Paper, Typography,
  Link as MUILink,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import useGlobal from 'global-state/store';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LaboxWS from 'icons/LaboxWS';
import { Info } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuth } from 'firebaseHooks/AuthContext';
import { getApp } from 'firebase/app';
import CreateAccountContent from './CreateAccountContent';
import SignInContent from './SignInContent';

export default function CreateAccountStep({ handleComplete }) {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const app = getApp();
  const auth = getAuth(app);
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    if (user) {
      handleComplete();
    }
  }, [handleComplete, user]);

  const ourSignInWithPopup = () => signInWithPopup(auth, provider);
  const [displayCreateAccount, setDisplayCreateAccount] = useState(true);
  const [displaySignIn, setDisplaySignIn] = useState(false);

  const expandIcon = (displayAll) => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        mt: 2,
        mb: 2,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          width: { xs: '100%', sm: '75%' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
          onClick={() => setDisplayCreateAccount(!displayCreateAccount)}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Typography component="span" variant="h6">
              {t('signin.create_your')}
              <Typography component="span" variant="h6" sx={{ fontWeight: 'bold', color: '#437A89' }}>
                {t('signin.info2')}
              </Typography>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  globalActions.setSnackbarMessage(
                    { message: t('signin.unique_login_info'), severity: 'info' },
                  );
                }}
                color="primary"
                sx={{ flexShrink: 0 }}
              >
                <Info sx={{ fontSize: 16, p: 0, m: 0 }} />
              </IconButton>
            </Typography>
            <IconButton size="large">
              {expandIcon(displayCreateAccount)}
            </IconButton>
          </Box>
        </Box>

        <Collapse
          in={displayCreateAccount}
          sx={{
            width: '100%',
          }}
        >
          <CreateAccountContent
            t={t}
            globalActions={globalActions}
            navigate={navigate}
            location={location}
            Link={Link}
            auth={auth}
            analytics={analytics}
            gtcuCookieName="agreed-to-general-terms-digitank-gauged-containers"
            ourSignInWithPopup={ourSignInWithPopup}
            createUserWithEmailAndPassword={createUserWithEmailAndPassword}
            logEvent={logEvent}
            sendEmailVerification={sendEmailVerification}
            LaboxWSIcon={LaboxWS}
            signinCallback={handleComplete}
          />
        </Collapse>

      </Paper>

      <Paper
        elevation={0}
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          width: { xs: '100%', sm: '75%' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
          onClick={() => setDisplaySignIn(!displaySignIn)}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Typography component="span" variant="h6">
              {t('signin.already_have_account')}
            </Typography>
            <Typography component="span" variant="h6">
              {t('signin.info1')}
              <Typography component="span" variant="h6" sx={{ fontWeight: 'bold', color: '#437A89' }}>
                {t('signin.info2')}
              </Typography>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  globalActions.setSnackbarMessage(
                    { message: t('signin.unique_login_info'), severity: 'info' },
                  );
                }}
                color="primary"
                sx={{ flexShrink: 0 }}
              >
                <Info sx={{ fontSize: 16, p: 0, m: 0 }} />
              </IconButton>
            </Typography>
            <IconButton size="large">
              {expandIcon(displaySignIn)}
            </IconButton>
          </Box>
        </Box>

        <Collapse
          in={displaySignIn}
          sx={{
            width: '100%',
          }}
        >
          <SignInContent
            t={t}
            globalActions={globalActions}
            navigate={navigate}
            location={location}
            Link={Link}
            auth={auth}
            analytics={analytics}
            gtcuCookieName="agreed-to-general-terms-digitank-gauged-containers"
            ourSignInWithPopup={ourSignInWithPopup}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            logEvent={logEvent}
            sendEmailVerification={sendEmailVerification}
            LaboxWSIcon={LaboxWS}
            signinCallback={handleComplete}
          />
        </Collapse>
      </Paper>

      <Typography>
        {t('welcome.contact_message')}
        <MUILink
          component={Link}
          to="/contact"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@labox-apps.com
        </MUILink>
      </Typography>

    </Box>
  );
}
