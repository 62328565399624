import {
  collection, doc, getDoc, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';

export async function getCellarTanksAndCertificate() {
  return null;
}

export async function getTankAndCertificate(
  tankRef,
  tankAndCertif,
  setCertificatePath,
  setCertificatePdfPath,
  setValidation,
  globalState,
  setLoadingState,
) {
  const tankDoc = await getDoc(tankRef);
  const tankData = tankDoc.data();
  // eslint-disable-next-line no-param-reassign
  tankAndCertif.tank = tankData;
  getCertificate(
    tankDoc.ref.firestore,
    globalState.activeOrganization,
    tankDoc.ref.path,
    tankAndCertif,
    tankData.activeCertificate,
    setCertificatePath,
    setCertificatePdfPath,
    setValidation,
    globalState,
    setLoadingState,
  );
}

const isNewCertificateModel = (certifData) => Boolean(certifData.reference);

export const getCertifData = async (db, tankPath, certifData, setCertificatePdfPath) => {
  if (isNewCertificateModel(certifData)) {
    const certifRef = doc(db, certifData.reference.path);
    const certifDoc = await getDoc(certifRef);
    setCertificatePdfPath(`gs://labox-ws.appspot.com/${certifData.reference.path}.pdf`);
    return {
      ...certifDoc.data(),
      id: certifDoc.id,
      expirationDate: new Date(certifDoc.data().expirationDate.toMillis()),
    };
  }
  setCertificatePdfPath(`gs://labox-ws.appspot.com/${tankPath}/gaugeCertificates/${certifData.id}.pdf`);
  return {
    ...certifData,
    expirationDate: new Date(certifData.expirationDate),
  };
};

const verify = (newValidation, setValidation) => {
  setValidation({ ...newValidation, hasBeenChecked: newValidation.status !== 'reset' });
};

async function getCertificate(
  firestore,
  activeOrganization,
  path,
  tankAndCertif,
  certifId,
  setCertificatePath,
  setCertificatePdfPath,
  setValidation,
  globalState,
  setLoadingState,
) {
  const certifRef = doc(firestore, path, 'gaugeCertificates', certifId);
  setCertificatePath(certifRef.path);
  try {
    const certifDoc = await getDoc(certifRef);
    const certifData = await getCertifData(firestore, path, certifDoc.data(), setCertificatePdfPath);
    const validationData = await getMostRecentValidation(certifRef.id, activeOrganization, firestore);
    if (!validationData) {
      setValidation({ hasBeenChecked: false });
    } else {
      verify(validationData, setValidation);
    }
    // eslint-disable-next-line no-param-reassign
    tankAndCertif.certificate = certifData;
    // eslint-disable-next-line no-param-reassign
    tankAndCertif.isLoaded = true;
    setLoadingState('loaded');
  } catch (err) {
    setLoadingState(globalState.networkState === 'offline' ? 'error' : 'throw');
    if (globalState.networkState !== 'offline') throw err;
  }
}

export async function getMostRecentValidation(certificateNumber, activeOrganization, db) {
  const validationsRef = collection(
    db,
    `organizations/${activeOrganization}/apps/digitank-gauged-containers/validations`,
  );

  const validationQuery = query(
    validationsRef,
    where('certificateNumber', '==', certificateNumber),
    orderBy('dateAndTime', 'desc'),
    limit(1),
  );

  const querySnapshot = await getDocs(validationQuery);

  if (!querySnapshot.empty) {
    const mostRecentValidation = querySnapshot.docs[0].data();
    return mostRecentValidation;
  }
  return null;
}
