import React from 'react';
import { navigatorNetworkState } from 'components/utils';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

const initialState = {
  cookiesAccepted: false,
  clientPath: '',
  networkState: navigatorNetworkState(),
  userStatus: 'initial',
  userPlan: '',
  globalSnackbar: { display: false, mesage: '', severity: 'info' },
  roles: [],
  wsRoles: [],
  activeOrganization: '',
  addTanksButtonAvailable: false,
  extraOperationFields: [],
  gaugerCollaborations: [],
  orgUnits: {
    volume: 'hL',
  },
  // onboardingGaugerOrganizationId: 'Y9FZuGEJnYvsVXKJ3OX1',
  onboardingGaugerOrganizationId: 'rjTZVS7EJEOqiyibjs6a', // This is in emulator
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
