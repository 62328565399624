import React, { useState } from 'react';
import useGlobal from 'global-state/store';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  collection, doc, getDoc, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import { roundNoFixed } from 'components/utils';
import { unit } from 'mathjs';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

function DailyInventory() {
  const firestore = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { activeOrganization } = globalState;
  const { t } = useTranslation();

  const tanksRef = collection(firestore, `organizations/${activeOrganization}/apps/digitank-gauged-containers/tanks`);
  const operationsRef = collection(firestore, `organizations/${activeOrganization}/apps/`
    + 'digitank-gauged-containers/operations');
  const [loading, setLoading] = useState(false);

  const isNewCertificateModel = (certifData) => Boolean(certifData.reference);

  const fetchTankData = async (tanksSnapshot) => {
    const tankDataMap = {};

    const tankPromises = tanksSnapshot.docs.map(async (dct) => {
      const tankData = dct.data();
      const activeCertificateId = tankData.activeCertificate;

      if (activeCertificateId) {
        const certifRef = doc(firestore, `organizations/${activeOrganization}/apps/`
          + `digitank-gauged-containers/tanks/${dct.id}/gaugeCertificates/${activeCertificateId}`);
        const certificateDoc = await getDoc(certifRef);

        const certificateData = certificateDoc.data();

        if (isNewCertificateModel(certificateData)) {
          const referenceDocRef = certificateData.reference;
          const referenceDoc = await getDoc(referenceDocRef);

          tankData.fullActiveCert = referenceDoc.data();
        } else {
          tankData.fullActiveCert = certificateData;
        }
      }

      tankDataMap[dct.id] = tankData;
    });

    await Promise.all(tankPromises);

    return tankDataMap;
  };

  const fetchLastOperations = async () => {
    const tanksSnapshot = await getDocs(tanksRef);
    const tankIds = tanksSnapshot.docs.map((dct) => dct.id);
    const tankDataMap = await fetchTankData(tanksSnapshot);

    const lastOpsPromises = tankIds.map(async (tankId) => {
      const queryDocs = query(
        operationsRef,
        where('tankNumber', '==', tankId),
        orderBy('dateAndTime', 'desc'),
        limit(1),
      );
      const snapshot = await getDocs(queryDocs);
      return snapshot.empty ? null : snapshot.docs[0].data();
    });

    let lastOps = await Promise.all(lastOpsPromises);
    lastOps = lastOps.filter((op) => op !== null);
    return { lastOps, tankDataMap };
  };

  const generateXLSX = (lastOpsAndTanks) => {
    const ws = XLSX.utils.json_to_sheet([]);
    const { lastOps, tankDataMap } = lastOpsAndTanks;
    const volumeUnit = lastOps[0].form.volumeUnit || 'hL';

    const headerTranslations = {
      cellarName: t('daily_inventory.headers.cellarName'),
      tankName: t('daily_inventory.headers.tankName'),
      volume: t('daily_inventory.headers.finalVolume', { unit: volumeUnit }),
      abvAt20c: t('daily_inventory.headers.abvAt20c'),
      pureAlcohol: t('daily_inventory.headers.pureAlcohol', { unit: volumeUnit }),
      utilCapacity: t('daily_inventory.headers.util_capacity', { unit: volumeUnit }),
    };

    const headers = Object.values(headerTranslations);

    globalState.extraOperationFields.forEach((field) => {
      headers.push(field.display);
    });

    headers.push(t('daily_inventory.headers.last_op_date'));

    // Add headers to the worksheet
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

    // Define column widths
    const columnWidths = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      ...globalState.extraOperationFields.map(() => ({ wch: 30 })),
      { wch: 30 },
    ];

    ws['!cols'] = columnWidths;

    // console.log(lastOps);

    const data = lastOps.map((op) => {
      const { compartment } = tankDataMap[op.tankNumber].fullActiveCert;
      const maxVolume = roundNoFixed(unit(compartment.maxVolume, 'L').toNumber(volumeUnit), 4);
      const row = [
        op.cellarName,
        op.tankName,
        op.form.results.volume,
        op.form.results.volumeConvResult?.abvAt20c?.value || '',
        op.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || '',
        maxVolume,
      ];

      // Add extra fields values
      globalState.extraOperationFields.forEach((field) => {
        const storedField = op.form.extraFields?.find((f) => f.name === field.name);
        row.push(storedField?.value || '');
      });

      const date = new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000);
      const formattedDate = date.toLocaleString('fr-FR', { hour12: false });

      row.push(formattedDate);

      return row;
    });

    // console.log('data', data);

    // Add data to the worksheet
    XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: 'A2' });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Inventaire journalier');

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    XLSX.writeFile(workbook, `inventaire-journalier-${formattedDate}.xlsx`);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const lastOpsAndTanks = await fetchLastOperations();
      generateXLSX(lastOpsAndTanks);
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err.message}`, severity: 'error' });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      onClick={handleClick}
      endIcon={<DownloadIcon />}
    >
      {t('daily_inventory.button')}
    </LoadingButton>
  );
}

export default DailyInventory;
