import React, {
  useState, useMemo, useEffect,
} from 'react';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import {
  doc,
  getDoc,
} from 'firebase/firestore';
import TankCard from 'components/operation/TankCard';
import { useQuery } from 'components/utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import Compartment from './Compartment';
import { getTankAndCertificate } from './tankQueries';

export default function Operation(props) {
  const { dispatchMenuActions } = props;
  const analytics = getAnalytics();
  const queryParams = useQuery(useMemo, useLocation);
  const tankPath = queryParams.get('tankPath');
  const tankPathElements = tankPath.toString().split('/');
  const tankId = tankPathElements[5];
  const db = useFirestore();
  const [globalState] = useGlobal();
  const tankRef = doc(db, tankPath);
  const [certificatePath, setCertificatePath] = useState('');
  const [certificatePdfPath, setCertificatePdfPath] = useState('');
  const [validation, setValidation] = useState({ hasBeenChecked: false });
  const tankAndCertif = useMemo(() => ({
    isLoaded: false,
    tankPath,
    certificate: undefined,
    tank: undefined,
  }), [tankPath]);
  const [loadingState, setLoadingState] = useState('notLoaded');
  const { t } = useTranslation();
  const [cellarData, setCellarData] = useState(null);

  useEffect(() => {
    const fetchCellarDoc = async () => {
      if (globalState.activeOrganization && tankAndCertif?.tank?.cellar) {
        try {
          const cellarDocRef = doc(db, `organizations/${globalState.activeOrganization}/apps/`
            + `digitank-gauged-containers/cellars/${tankAndCertif?.tank?.cellar}`);
          const cellarDocSnapshot = await getDoc(cellarDocRef);
          if (cellarDocSnapshot.exists()) {
            setCellarData(cellarDocSnapshot.data());
          }
        } catch (error) {
          console.error('Error fetching cellar document: ', error);
        }
      }
    };

    fetchCellarDoc();
  }, [tankAndCertif?.tank?.cellar, db, globalState.activeOrganization]);

  const verify = (newValidation) => {
    setValidation({ ...newValidation, hasBeenChecked: newValidation.status !== 'reset' });
  };

  useEffect(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  useEffect(() => {
    if (loadingState === 'notLoaded' && globalState.clientPath !== '') {
      setLoadingState('loading');
      logEvent(analytics, 'operation_page');
      getTankAndCertificate(
        tankRef,
        tankAndCertif,
        setCertificatePath,
        setCertificatePdfPath,
        setValidation,
        globalState,
        setLoadingState,
      );
    }
  }, [analytics, db, globalState, globalState.activeOrganization,
    globalState.clientPath, globalState.networkState, loadingState, tankAndCertif, tankRef]);

  if (loadingState === 'loaded') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3,
          gap: 2,
        }}
      >

        <TankCard
          setLoadingState={setLoadingState}
          tankPath={tankPath}
          tankName={tankAndCertif.tank.name}
          tankNumber={tankId}
          cellar={tankAndCertif.tank.cellar}
          cellarName={cellarData?.name}
          certificate={tankAndCertif.certificate}
          validation={validation}
          verify={verify}
          dispatchMenuActions={dispatchMenuActions}
        />

        <Compartment
          compartment={tankAndCertif.certificate.compartment}
          tankNumber={tankId}
          certificatePath={certificatePath}
          cellar={tankAndCertif.tank.cellar}
          dispatchMenuActions={dispatchMenuActions}
          certificatePdfPath={certificatePdfPath}
          tankName={tankAndCertif.tank.name}
          cellarName={cellarData?.name}
          certificateName={tankAndCertif.certificate.certificateName}
        />
      </Box>
    );
  } if (loadingState === 'error') {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
        gap: 2,
      }}
      >

        <Typography
          component="span"
          sx={{ maxWidth: 800 }}
        >
          {t('tank_selection.offline_tank_fetch_error')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          endIcon={<ReplayIcon />}
        >
          {t('reload')}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
