import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import moment from 'moment';
import { replaceComma } from 'components/utils';
import useGlobal from 'global-state/store';
import modelInfoFr from '../../../../staticFiles/modele-info.csv';

export default function TankInfoDropZone(props) {
  const [, globalActions] = useGlobal();
  const {
    formik, errorHandler, aborthandler, cellars, acceptedHeightUnit, acceptedVolumeUnit, update = false,
  } = props;
  const { t } = useTranslation();

  function getExample() {
    return modelInfoFr;
  }

  const callback = (acceptedFiles, setfileName) => {
    // #TODO make sure these are processed before you can submit again
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      const parsedCsv = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
      const errorMessage = t('tank_creation.can_not_parse_csv');
      if (parsedCsv.errors.length > 0) {
        globalActions.setSnackbarMessage({
          message: `${errorMessage}\n${parsedCsv.errors[0].message}`,
          severity: 'error',
        });
      } else {
        try {
          setFormikValues(modelFromData(parsedCsv.data[0]));
        } catch (error) {
          globalActions.setSnackbarMessage({ message: errorMessage, severity: 'error' });
        }
        setfileName(file.path);
      }
    };

    reader.readAsText(file);
  };

  function modelFromData(data) {
    // #TODO handling english headers
    return {
      cellar: data.chai,
      certificateNumber: data.certificat,
      tankId: data.identification,
      provider: data.prestataire_de_jaugeage,
      heightUnit: data.unites_de_hauteur,
      volumeUnit: data.unites_de_volume,
      capacity: replaceComma(data.capacite_citerne),
      nominalVolume: replaceComma(data.volume_nominal),
      expirationDate: moment(
        data.date_limite_de_validite,
        'DD/MM/YYYY',
      ).toDate(),
      gaugeDate: moment(data.date_du_certificat, 'DD/MM/YYYY').toDate(),
    };
  }

  function setFormikValues(model) {
    if (update) {
      if (model.cellar !== formik.values.cellar) {
        globalActions.setSnackbarMessage({
          message: t('tank_creation.not_same_cellar'),
          severity: 'error',
        });
      }
      if (model.tankId !== formik.values.tankId) {
        globalActions.setSnackbarMessage({
          message: t('tank_creation.not_same_tank_id'),
          severity: 'error',
        });
      }
    } else {
      formik.setFieldValue('cellar', existingsOrEmptyString(model.cellar));
      formik.setFieldValue('tankId', model.tankId);
    }
    formik.setFieldValue('certificateNumber', model.certificateNumber);
    formik.setFieldValue('provider', model.provider);
    formik.setFieldValue('heightUnit', unitOrEmptyString(acceptedHeightUnit, model.heightUnit));
    formik.setFieldValue('volumeUnit', unitOrEmptyString(acceptedVolumeUnit, model.volumeUnit));
    formik.setFieldValue('capacity', model.capacity);
    formik.setFieldValue('nominalVolume', model.nominalVolume);
    formik.setFieldValue('expirationDate', model.expirationDate);
    formik.setFieldValue('gaugeDate', model.gaugeDate);
  }

  function existingsOrEmptyString(newCellar) {
    if (!cellars.includes(newCellar)) {
      return '';
    }
    return newCellar;
  }

  function unitOrEmptyString(availableUnits, newUnit) {
    if (!availableUnits.includes(newUnit)) {
      return '';
    }
    return newUnit;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <FileDropzone
        text={t('tank_creation.upload_csv_text')}
        callback={callback}
        accept={{ 'text/csv': ['.csv'] }}
      />
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
    </Box>
  );
}
