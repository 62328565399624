import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { getAnalytics } from 'firebase/analytics';

const AnalyticsContext = createContext();

export function AnalyticsProvider({ app, children }) {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const analyticsInstance = getAnalytics(app);
    setAnalytics(analyticsInstance);
    setLoading(false);
  }, [app]);

  const value = useMemo(() => ({
    analytics,
    loading,
  }), [analytics, loading]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  return useContext(AnalyticsContext);
}
