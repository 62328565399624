import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '@mui/material/Paper';
import {
  where,
} from 'firebase/firestore';
import { useAuth } from 'firebaseHooks/AuthContext';
import Papa from 'papaparse';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Filters from 'components/admin/managingTanks/TankFilters';
import OurPagination from 'components/OurPagination';
import { Button } from '@mui/material';
import { downloadCsvFile } from '../../utils';
import OperationDetailDialog from './OperationDetailDialog';
import OperationListItem from './OperationListItem';
import DailyInventory from '../DailyInventory';

export default function Operations() {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();

  const computeListOfConditions = useCallback((formikValues) => {
    let conditions = [
      where('dateAndTime', '<=', formikValues.endDate),
      where('dateAndTime', '>=', formikValues.startDate),
    ];
    if (formikValues.cellar !== '') {
      conditions = [...conditions, where('cellar', '==', formikValues.cellar)];
    }
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    cellar: '',
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d;
  }

  function initialEndDate() {
    const d = new Date();
    d.setHours(23, 59, 0, 0); // next before midnight
    return d;
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_operations', {
        user_uid: user?.uid,
        appName: 'Digitank, Gauged Containers',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%',
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.operations')}
      </Typography>
      {globalState.activeOrganization !== '' && (
        <OurPagination
          collectionPath={`organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers/operations`}
          listOfConditions={listOfConditions}
          orderByAttribute="dateAndTime"
          filters={(
            <OperationListFilters
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
            />
          )}
          list={(
            <OperationList />
          )}
        />
      )}
      {globalState.activeOrganization === '' && <CircularProgress />}
    </Box>
  );
}

function OperationListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
}) {
  const validationSchema = Yup.object().shape({
    cellar: Yup.string().nullable(),
  });
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formikInitValues,
    validationSchema,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <Filters formik={formik} datePickersLabel={t('admin.made')} />
    </Box>
  );
}

function OperationList({ docs }) {
  const { t } = useTranslation();
  const [operationsCsv, setoperationsCsv] = useState('');
  const [activeOperation, setactiveOperation] = useState('');
  const [open, setOpen] = useState(false);

  const formatOperationsForCsv = useCallback((newOperations) => newOperations.map((opDoc) => {
    const op = opDoc.data();
    return {
      id: op.id,
      [t('operation_csv_headers.tankNumber')]: op.tankName || op.tankNumber,
      date: new Date(op.dateAndTime.toMillis()).toLocaleString(),
      [t('operation_csv_headers.cellar')]: op.cellarName || op.cellar,
      [t('operation_csv_headers.certificateNumber')]: op.certificateName || op.certificateNumber,
      [t('operation_csv_headers.finalVolume', { unit: op.form.volumeUnit || 'HL' })]: op.form.results.volume,

    };
  }), [t]);

  const refresh = useCallback(async () => {
    const operationListCsv = Papa.unparse(
      formatOperationsForCsv(docs),
      {
        delimiter: ';',
      },
    );
    setoperationsCsv(operationListCsv);
  }, [docs, formatOperationsForCsv]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleOpen = (operation) => {
    setactiveOperation(operation);
    setOpen(true);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <ListHeader />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {docs.map((op, i) => (
            <div key={op.id}>
              <OperationListItem
                operation={op.data()}
                index={i}
                handleOpen={handleOpen}
              />
              <Divider />
            </div>
          ))}
        </List>

      </Paper>
      <OperationDetailDialog
        operation={activeOperation}
        open={open}
        setOpen={setOpen}
      />
      <Paper
        elevation={0}
        sx={{
          p: 3,
          display: 'flex',
          gap: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          endIcon={<DownloadIcon />}
          onClick={(e) => {
            downloadCsvFile('operations', operationsCsv);
          }}
        >
          {t('download_operations')}
        </Button>
        <DailyInventory />
      </Paper>

    </Box>
  );
}

function ListHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography>{t('admin.tank_id')}</Typography>
      <Typography>{t('admin.cellar')}</Typography>
      <Typography sx={{ width: 155 }}>{t('admin.date')}</Typography>
    </Box>
  );
}
