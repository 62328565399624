import * as yup from 'yup';

export default function validationSchema(t) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: 0,
      tavMax: 100,
      alcoholUnit: '% vol.',
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: -10,
      tempMax: 40,
      tempUnit: '°C',
    }),
  };

  return yup.object().shape({
    measuredTav: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(0, validationPhrases.tavValidationPhrase)
      .max(100, validationPhrases.tavValidationPhrase),
    tavTemperature: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(-10, validationPhrases.tempValidationPhrase)
      .max(40, validationPhrases.tempValidationPhrase),
    liquidTemperature: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(-10, validationPhrases.tempValidationPhrase)
      .max(40, validationPhrases.tempValidationPhrase),
  });
}
