import React from 'react';
import {
  Button,
  Typography,
  Box,
} from '@mui/material';
import Cookies from 'universal-cookie';
import { logEvent } from 'firebase/analytics';
import googleLogo from 'staticFiles/googleLogo.png';
import AcceptGTCU from './AcceptGTCU';

export default function SocialSigninCard(props) {
  const {
    afterSignInRedirect, t, analytics, navigate, Link, gtcuCookieName,
    ourSignInWithPopup, signinCallback = () => {}, createAccount = false,
  } = props;
  const cookies = new Cookies();
  const [acceptedGTCU, setacceptedGTCU] = React.useState(false);
  const [showGTCUError, setshowGTCUError] = React.useState(false);

  const signIn = async () => {
    if (!acceptedGTCU) {
      return setshowGTCUError(true);
    }
    localStorage.setItem(gtcuCookieName, 'true');
    cookies.set(gtcuCookieName, 'true');
    try {
      const result = await ourSignInWithPopup();
      signinCallback();
      if (afterSignInRedirect) {
        navigate(afterSignInRedirect);
      }
      return result;
    } catch (error) {
      logEvent(analytics, 'login_error', {
        error_message: error.message,
      });
    }
    return null;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        width: { xs: '100%', sm: '88%' },
        py: 3,
        px: 1,
        maxWidth: 670,
        border: 1,
        borderRadius: 1,
      }}
    >
      <Typography component="span" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {createAccount ? t('signin.create_account_button') : t('signin.login')}
        {' '}
        {t('with')}
        {' '}
        Google
      </Typography>
      <AcceptGTCU
        acceptedGTCU={acceptedGTCU}
        setacceptedGTCU={setacceptedGTCU}
        showGTCUError={showGTCUError}
        setshowGTCUError={setshowGTCUError}
        t={t}
        Link={Link}
      />
      <Button
        variant="contained"
        color="inherit"
        onClick={signIn}
      >
        <img style={{ width: 20, marginRight: 5 }} src={googleLogo} alt="Google Labox" />
        {t('signin.login')}
      </Button>
    </Box>
  );
}
