import {
  collection, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export default async function loadLastOperation(
  tankId,
  operationItemId,
  setFields,
  setResults,
  setVolumeToAdd,
  setApKey,
  globalState,
  globalActions,
  t,
  firestore,
) {
  const operationsRef = collection(firestore, `organizations/${globalState.activeOrganization}/apps/`
    + 'digitank-gauged-containers/operations');

  const queryDocs = query(
    operationsRef,
    where('tankNumber', '==', tankId),
    orderBy('dateAndTime', 'desc'),
    limit(1),
  );
  const snapshot = await getDocs(queryDocs);
  if (!snapshot.empty) {
    const fsOperation = snapshot.docs[0].data();
    setFields(fsOperation.form.fields);
    setResults(fsOperation.form.results);
    setVolumeToAdd(fsOperation.form.volumeToAdd);
    setApKey(uuidv4());
    return;
  }

  const savedOperationItem = localStorage.getItem(operationItemId);
  if (savedOperationItem) {
    const savedOperation = JSON.parse(savedOperationItem);
    try {
      setFields(savedOperation.fields);
      setResults(savedOperation.results);
      setVolumeToAdd(savedOperation.volumeToAdd);
      setApKey(uuidv4());
    } catch (e) {
      globalActions.setSnackbarMessage({ message: t('operation.no_saved_operation'), severity: 'warning' });
      localStorage.removeItem(operationItemId);
    }
  } else {
    globalActions.setSnackbarMessage({ message: t('operation.no_saved_operation'), severity: 'warning' });
  }
}
