import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useFirestore } from './FirestoreContext';

const useFirestoreCollectionDataOnce = (collectionPath, options = {}) => {
  const firestore = useFirestore();
  const [data, setData] = useState([]);
  const [loadingState, setLoadingState] = useState('initial');
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoadingState('loading');

    if (typeof collectionPath !== 'string') {
      const errorMessage = `Invalid collectionPath: expected a string but got ${typeof collectionPath}`;
      setLoadingState('error');
      throw new Error(errorMessage);
    }

    try {
      const colRef = collection(firestore, collectionPath);

      const querySnapshot = await getDocs(colRef);
      const docsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(docsData);
      setLoadingState('success');
    } catch (err) {
      setError(err);
      setLoadingState('error');
      throw err;
    }
  }, [collectionPath, firestore]);

  useEffect(() => {
    if (loadingState === 'initial') {
      fetchData();
    }
  }, [collectionPath, fetchData, firestore, loadingState]);

  return { data, loadingState, error };
};

export default useFirestoreCollectionDataOnce;
