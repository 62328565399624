import React, {
  createContext, useContext, useMemo, useEffect, useState,
} from 'react';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

const RemoteConfigContext = createContext(null);

export function RemoteConfigProvider({ app, children }) {
  const remoteConfig = getRemoteConfig(app);
  const [configValues, setConfigValues] = useState({
    maintenance: '',
    refresh_app: '',
    refresh_app_specific: '',
  });
  const [configStatuses, setConfigStatuses] = useState({
    maintenance: 'loading',
    refresh_app: 'loading',
    refresh_app_specific: 'loading',
  });

  useEffect(() => {
    async function fetchConfigValues() {
      remoteConfig.settings = {
        minimumFetchIntervalMillis: 600000,
        fetchTimeoutMillis: 600000,
      };
      await fetchAndActivate(remoteConfig);
      setConfigValues({
        maintenance: getValue(remoteConfig, 'maintenance').asString(),
        refresh_app: getValue(remoteConfig, 'refresh_app').asString(),
        refresh_app_specific: getValue(remoteConfig, 'refresh_app_specific').asString(),
      });
      setConfigStatuses({
        maintenance: 'success',
        refresh_app: 'success',
        refresh_app_specific: 'success',
      });
    }

    fetchConfigValues();
  }, [remoteConfig]);

  const value = useMemo(() => ({
    remoteConfig,
    configValues,
    configStatuses,
  }), [remoteConfig, configValues, configStatuses]);

  return (
    <RemoteConfigContext.Provider value={value}>
      {children}
    </RemoteConfigContext.Provider>
  );
}

export const useRemoteConfig = () => useContext(RemoteConfigContext);
