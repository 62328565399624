import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, Link as MUILink, Paper, Typography,
} from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function ConnectWithGauger() {
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <Paper sx={{
      p: 3, display: 'flex', flexDirection: 'column', gap: 1,
    }}
    >
      <Typography>{t('gauger_connect.title')}</Typography>
      <Typography variant="caption">{t('gauger_connect.connect')}</Typography>
      <Button variant="outlined" onClick={() => setOpenDialog(true)}>{t('continue')}</Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Typography sx={{ textAlign: 'left', mt: 2 }}>
            <Trans i18nKey="gauger_connect.need_subscirption">
              <MUILink
                component={Link}
                to="subscription"
                color="primary"
                sx={{ fontWeight: 'bold' }}
              >
                subscription
              </MUILink>
            </Trans>
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
    </Paper>

  );
}
