import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useLocation } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import GaugerCollaboration from 'components/admin/gaugerCollaboration/GaugerCollaboration';
import { useFunctions } from 'firebaseHooks/FunctionsContext';

export default function ActiveSubscriptions(props) {
  const { activeSubscriptions } = props;
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const gaugedContainersCustomerPortal = httpsCallable(functions, 'gaugedContainersCustomerPortal');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    if (search) {
      navigate(pathname, { replace: true });
    }
  }, [navigate, location]);

  const handleManageSubscriptionsClick = async () => {
    try {
      const result = await gaugedContainersCustomerPortal({ organizationId: globalState.activeOrganization });
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography sx={{ flexGrow: 1 }} component="span" variant="h6">
        {activeSubscriptions.length > 1 ? t('subscription.your_active_subs') : t('subscription.your_sub')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {activeSubscriptions.map((subscription) => (
          <ActiveSubscription key={subscription.id} subscription={subscription} />
        ))}
      </Box>

      <Button
        onClick={handleManageSubscriptionsClick}
        color="primary"
        variant="contained"
        endIcon={<ArrowRight />}
        size="large"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography componant="span">
            {t('subscription.manage_sub')}
          </Typography>
          <Typography componant="span" sx={{ fontSize: 12 }}>
            {t('subscription.consult_invoices')}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}

function ActiveSubscription(props) {
  const { subscription } = props;
  const { t } = useTranslation();

  const subscriptionActivePrice = subscription.prices.find((p) => p.active === true);

  const priceTitle = subscriptionActivePrice.nickname;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        gap: 3,
      }}
    >
      <Card
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <Typography sx={{ flexGrow: 1 }} component="span" variant="h6">
          {priceTitle}
        </Typography>
        {subscription.cancelAt && (
        <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
          <Typography color="red" component="span" variant="subtitle2">
            {t('subscription.cancel_at')}
            {': '}
          </Typography>
          {subscription.cancelAt.toDate().toLocaleDateString(t('locale'), {
            year: 'numeric', month: 'long', day: 'numeric',
          })}
        </Typography>
        )}
        {subscription.cancelAt === null && (
        <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
          <Typography color="primary" component="span" variant="subtitle2">
            {t('subscription.renew_at')}
            {': '}
          </Typography>
          {subscription.currentPeriodEnd.toDate().toLocaleDateString(t('locale'), {
            year: 'numeric', month: 'long', day: 'numeric',
          })}
        </Typography>
        )}
        {subscription.code
            && (
            <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
              {`${t('codes.activated_code')}: ${subscription.code}`}
            </Typography>
            )}
      </Card>
      <GaugerCollaboration />
    </Box>
  );
}
