import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Autocomplete } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

export default function SuperAdmins() {
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const firestore = useFirestore();
  const [activating, setactivating] = useState(false);
  const [globalState, globalActions] = useGlobal();

  const connectToGaugerCall = httpsCallable(functions, 'connectToGaugerCall');

  if (!globalState.wsRoles.includes('admin')) {
    return null;
  }

  return (
    <Formik
      initialValues={{ gaugerName: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.gaugerName) {
          errors.gaugerName = t('validations.required');
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const gaugerIdForName = {
            'Cognac Jaugeage': globalState.onboardingGaugerOrganizationId,
            'Labox Apps': 'u86FcSKbbJ8a7qzgnmBk',
          };
          setactivating(true);

          const updateBillingMethod = async (method) => {
            const orgPath = `organizations/${globalState.activeOrganization}/apps/digitank-gauged-containers`;
            const docRef = doc(firestore, orgPath);

            try {
              await updateDoc(docRef, {
                billingMethod: method,
              });
            } catch (error) {
              globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
            }
          };

          await updateBillingMethod('manual');

          await connectToGaugerCall({
            organizationId: globalState.activeOrganization,
            gaugerOrganizationId: gaugerIdForName[values.gaugerName],
            appName: 'digitank-gauged-containers',
          });
          globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
        } catch (error) {
          globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
          setSubmitting(false);
        } finally {
          setactivating(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 2,
            padding: 3,
            mt: 6,
          }}
          onSubmit={handleSubmit}
        >
          <Typography component="span" variant="subtitle">
            {'Fonctionnalité Super Admin: \nInitier la connection de cette organization au Jaugeur.'}
            {'\nLe Jaugeur recevra une notification dans son panel d\'admin.'}
          </Typography>
          <Autocomplete
            sx={{ width: 300 }}
            multiple
            freeSolo
            options={['Cognac Jaugeage', 'Labox Apps']}
            onChange={(event, newValue) => setFieldValue('gaugerName', newValue)}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Nom du Jaugeur"
                error={touched.gaugerName && Boolean(errors.gaugerName)}
                helperText={errors.gaugerName && touched.gaugerName && errors.gaugerName}
              />
            )}
          />
          <LoadingButton
            size="medium"
            loading={activating}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Envoyer
          </LoadingButton>
        </Paper>
      )}
    </Formik>
  );
}
