import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { nominalVolumeFromCompartment } from 'components/utils';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import Grid from '@mui/material/Unstable_Grid2';

export default function OperationDetails(props) {
  const { operation } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 3, width: '100%',
      }}
    >
      <Typography variant="body2">
        {t('tank_selection.cellar')}
        {': '}
        {operation.cellarName || operation.cellar}
        <br />
        {t('tank_selection.certificate')}
        {': '}
        {operation.certificateName || operation.certificateNumber}
        <br />
        <br />
        {t('tank_selection.operation_date')}
        {': '}
        {new Date(operation.dateAndTime.toMillis()).toLocaleString()}
        <br />
        ID operation:
        {' '}
        {operation.id}
        <br />
        {t('admin.operator')}
        {': '}
        {operation.userEmail}
      </Typography>
      <CompartmentOperation operation={operation} />
    </Box>
  );
}

function CompartmentOperation(props) {
  const { t } = useTranslation();
  const { operation } = props;

  const volumeConvRes = operation.form.results.volumeConvResult;

  return (
    <Box
      sx={{
        display: 'flex', gap: 3, flexDirection: 'column', width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 0, flexDirection: 'row' }}>
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          {t('operation.nominal_volume', { unit: 'HL' })}
          {': '}
          {nominalVolumeFromCompartment(operation.form, t)}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
      }}
      >
        <Box sx={{ flexGrow: 1 }}>{`${t('operation.height')}: ${operation.form.fields.height.value}`}</Box>
        <Box sx={{ flexGrow: 1 }}>
          {`${t(
            'operation.volume',
            { unit: operation.form.volumeUnit || 'HL' },
          )}: ${operation.form.fields.volume.value}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {`Volume ${operation.form.volumeChange === 'add'
            ? t('added')
            : t('removed')} (${operation.form.volumeUnit || 'HL'}): ${operation.form.volumeToAdd.value}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {t('operation.final_volume')}
          {` (${operation.form.volumeUnit || 'HL'}): ${operation.form.results.volume}`}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {t('operation.final_height')}
          {` (mm): ${operation.form.results.height}`}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      {volumeConvRes && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography component="span">
              {volumeConvRes.abvAt20c ? t('abv_20c_res', {
                value: volumeConvRes.abvAt20c.value,
                unit: dynamicUnitName(volumeConvRes.abvAt20c.unit),
              }) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="span">
              {t('liquid_temp_res', {
                value: volumeConvRes.liquidTemperature.value,
                unit: dynamicUnitName(volumeConvRes.liquidTemperature.unit),
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="span">
              {volumeConvRes.volumeAtTemp ? t('volume_at_temp_res', {
                tempValue: volumeConvRes.volumeAtTemp.temperature.value,
                tempUnit: dynamicUnitName(volumeConvRes.volumeAtTemp.temperature.unit),
                value: volumeConvRes.volumeAtTemp.value,
                unit: dynamicUnitName(volumeConvRes.volumeAtTemp.unit),
              }) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component="span">
              {volumeConvRes.pureAlcoholVolumeAt20c ? t('pure_alcohol_res', {
                value: volumeConvRes.pureAlcoholVolumeAt20c.value,
                unit: dynamicUnitName(volumeConvRes.pureAlcoholVolumeAt20c.unit),
              }) : ''}
            </Typography>
          </Grid>
        </Grid>
      )}

      {operation.form.extraFields && operation.form.extraFields.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          {operation.form.extraFields.map((field) => (
            <Typography key={field.name} component="span">
              {`${field.display}: ${field.value}`}
            </Typography>
          ))}
        </Box>
      )}

    </Box>
  );
}
