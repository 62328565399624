import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import MLink from '@mui/material/Link';

export default function TankInfoInputs(props) {
  const { t } = useTranslation();
  const {
    formik, cellars, acceptedHeightUnit, acceptedVolumeUnit, update = false,
  } = props;

  return (
    <Paper>
      <Box
        elevation={0}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >

        <FormControl sx={{ backgroundColor: 'white', minWidth: 195 }} disabled={update}>
          <InputLabel id="cellar-select-label">
            {t('tank_selection.cellar')}
          </InputLabel>

          <Select
            labelId="cellar-select-label"
            id="cellar"
            name="cellar"
            value={formik.values.cellar}
            label={t('tank_selection.cellar')}
            onChange={(event) => {
              formik.setFieldValue('cellar', event.target.value);
            }}
          >
            {cellars.map((cellar, i) => (
              <MenuItem value={cellar} key={cellar}>
                {cellar}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.cellar
            && formik.errors.cellar && (
              <Typography variant="caption" display="block" color="red">
                {formik.errors.cellar}
                <br />
                <MLink
                  color="inherit"
                  component={Link}
                  to="../cellars"
                >
                  {t('tank_creation.cellar_list_link')}

                </MLink>

              </Typography>
          )}
        </FormControl>
        <TextField
          disabled={update}
          id="tankId"
          name="tankId"
          autoComplete="off"
          variant="outlined"
          label={t('tank_creation.tank_id')}
          value={formik.values.tankId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.tankId && formik.touched.tankId}
          helperText={
            formik.errors.tankId
            && formik.touched.tankId
            && formik.errors.tankId
          }
        />
        <NumericFormat
          id="capacity"
          name="capacity"
          label={t('tank_creation.tank_capacity')}
          className="TextFieldBackground"
          variant="outlined"
          autoComplete="off"
          valueIsNumericString
          allowNegative={false}
          value={formik.values.capacity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator="."
          type="text"
          inputProps={{ inputMode: 'decimal' }}
          customInput={TextField}
          error={formik.errors.capacity && formik.touched.capacity}
          helperText={
            formik.errors.capacity
            && formik.touched.capacity
            && formik.errors.capacity
          }
          onValueChange={(values) => {
            formik.handleChange({
              target: {
                name: 'capacity',
                value: values.value,
              },
            });
          }}
        />
        <NumericFormat
          id="nominalVolume"
          name="nominalVolume"
          label={t('tank_creation.nominal_volume')}
          className="TextFieldBackground"
          variant="outlined"
          autoComplete="off"
          valueIsNumericString
          allowNegative={false}
          value={formik.values.nominalVolume}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator="."
          type="text"
          inputProps={{ inputMode: 'decimal' }}
          customInput={TextField}
          error={formik.errors.nominalVolume && formik.touched.nominalVolume}
          helperText={
            formik.errors.nominalVolume
            && formik.touched.nominalVolume
            && formik.errors.nominalVolume
          }
          onValueChange={(values) => {
            formik.handleChange({
              target: {
                name: 'nominalVolume',
                value: values.value,
              },
            });
          }}
        />
      </Box>
      <Box
        elevation={0}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }}
      >
        <TextField
          id="certificateNumber"
          name="certificateNumber"
          autoComplete="off"
          variant="outlined"
          label={t('tank_creation.certificate_number')}
          value={formik.values.certificateNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.certificateNumber && formik.touched.certificateNumber}
          helperText={
            formik.errors.certificateNumber
            && formik.touched.certificateNumber
            && formik.errors.certificateNumber
          }
        />
        <TextField
          id="provider"
          name="provider"
          autoComplete="off"
          variant="outlined"
          label={t('tank_creation.gauging_company')}
          value={formik.values.provider}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.provider && formik.touched.provider}
          helperText={
            formik.errors.provider
            && formik.touched.provider
            && formik.errors.provider
          }
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            name="gaugeDate"
            id="gaugeDate"
            inputFormat="DD/MM/YYYY"
            label={t('tank_creation.certificate_date')}
            value={formik.values.gaugeDate}
            onChange={(val) => {
              formik.setFieldValue('gaugeDate', val.toDate());
            }}
            error={formik.errors.gaugeDate && formik.touched.gaugeDate}
            renderInput={(params) => (
              <TextField
                sx={{ backgroundColor: 'white' }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />
          <DesktopDatePicker
            name="expirationDate"
            id="expirationDate"
            inputFormat="DD/MM/YYYY"
            label={t('admin.validity_date')}
            value={formik.values.expirationDate}
            error={formik.errors.expirationDate && formik.touched.expirationDate}
            onChange={(val) => {
              formik.setFieldValue('expirationDate', val.toDate());
            }}
            renderInput={(params) => (
              <TextField
                sx={{ backgroundColor: 'white', minWidth: 250 }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <FormControl sx={{ backgroundColor: 'white', minWidth: 195 }}>
          <InputLabel id="height-unit-select-label">
            {t('tank_creation.height_unit')}
          </InputLabel>
          <Select
            id="heightUnit"
            name="heightUnit"
            autoComplete="off"
            variant="outlined"
            label={t('tank_creation.height_unit')}
            value={formik.values.heightUnit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {acceptedHeightUnit.map((unit, i) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.heightUnit
          && formik.errors.heightUnit && (
            <Typography variant="caption" display="block" color="red">
              {formik.errors.heightUnit}
            </Typography>
          )}
        </FormControl>
        <FormControl sx={{ backgroundColor: 'white', minWidth: 195 }}>
          <InputLabel id="volume-unit-select-label">
            {t('tank_creation.volume_unit')}
          </InputLabel>
          <Select
            id="volumeUnit"
            name="volumeUnit"
            autoComplete="off"
            variant="outlined"
            label={t('tank_creation.volume_unit')}
            value={formik.values.volumeUnit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {acceptedVolumeUnit.map((unit, i) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
          {formik.errors.volumeUnit
            && formik.errors.volumeUnit && (
              <Typography variant="caption" display="block" color="red">
                {formik.errors.volumeUnit}
              </Typography>
          )}
        </FormControl>
      </Box>
    </Paper>
  );
}
