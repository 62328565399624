import {
  Box, Divider, List, TextField, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewCertificateItem from './NewCertificateItem';

export default function RejectedCertificates({
  rejectedCerts, onUpdateCellars,
  setRejectedCerts, setAcceptedCerts,
  handleRejectMessageChange, rejectMessage,
  acceptedCerts,
}) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: 'bold' }}>{t('accept_certificates.rejected_certificates')}</Typography>
      <List sx={{ width: '100%' }}>
        {rejectedCerts.map((cert, i) => (
          <div key={cert.certificateId}>
            <NewCertificateItem
              key={cert.certificateId}
              item={cert}
              rejectedCerts={rejectedCerts}
              setRejectedCerts={setRejectedCerts}
              setAcceptedCerts={setAcceptedCerts}
              onUpdateCellars={onUpdateCellars}
              acceptedCerts={acceptedCerts}
            />
            <Divider />
          </div>
        ))}
      </List>
      <TextField
        multiline
        rows={3}
        sx={{ width: '100%', mt: 1 }}
        size="small"
        fullWidth
        onChange={handleRejectMessageChange}
        value={rejectMessage}
        label={t('accept_certificates.reject_reason')}
      />
    </Box>
  );
}
